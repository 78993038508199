export const filterType = {
  SEDIMENT: 1,
  CARBON: 2,
  ACTIVE_CARBON: 3,
  MEMBRANE: 4,
  COCONUT: 5,
  ALKALI: 6,
  //   MINERAL: 7,
  //   DETOX: 8,
};
export const filterStatus = {
  NEW: 1,
  MATCHED: 2,
  STARTED: 3,
  FINISHED: 4,
};
