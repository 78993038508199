import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Progress, Select, Table } from 'antd';
import QRCode from 'react-qr-code';

// contexts
import { LanguageContext, FilterBarcodeContext, CompanyContext, AuthContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import Input from 'ui/reusables/input';
import { getParam } from 'helpers/page';
import history from 'history.js';
import userRole from 'resources/constants/userRole';
import { filterType } from 'resources/constants/filterType';
import { axiosClient } from 'service';
import { PhoneOutlined } from '@ant-design/icons';

const { Option } = Select;
let intervalId;
export default function CheckFilterBarcode(props) {
  const { id } = props.match.params;
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);

  const [filter, setFilter] = useState({});
  const [wrongBarcode, setWrongBarcode] = useState(false);
  const [remainFilterTime, setRemainFilterTime] = useState(0);

  const getFilterData = async () => {
    const response = await axiosClient.get('/wapi/v1/globalRoutes/checkFilterBarcode/' + id);
    if (response?.data?.error) {
      if (response?.data?.error?.code === 1010) setWrongBarcode(response.data);
    } else {
      console.log('errorsss', response.data);
      setFilter(response.data);
    }
  };

  useEffect(() => {
    if (id) {
      getFilterData();
    }
  }, [id]); //eslint-disable-line
  useEffect(() => {
    clearInterval(intervalId);
    intervalId = setInterval(() => {
      console.log(filter?.finishDate);
      setRemainFilterTime(filter?.finishDate - Date.now());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [filter?.finishDate]); //eslint-disable-line

  const SECOND = 1000;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{translations[appLanguage]['g516']}</h3>
      </div>

      <div className={styles.divider}>&nbsp;</div>
      <div style={{ textAlign: '-webkit-center' }}>
        <div style={{ fontWeight: 'bold', fontSize: 'Large', margin: '5px' }}>Filtre Sağlığı</div>
        {filter?.startDate && filter?.finishDate && (
          <Progress
            type='circle'
            percent={
              Date.now() > filter?.finishDate
                ? 0
                : parseInt(
                    ((filter?.finishDate - Date.now()) / (filter?.finishDate - filter?.startDate)) *
                      100
                  )
            }
            status={Date.now() > filter?.finishDate ? 'exception' : ''}
          />
        )}
      </div>
      {wrongBarcode && <div>{translations[appLanguage]['g518']}</div>}
      {!wrongBarcode && (
        <div style={{ width: '300px' }}>
          <Input
            label={translations[appLanguage]['g328']}
            value={filter?.barcode || '--'}
            disabled
          />
          <Input
            label={translations[appLanguage]['g207']}
            value={
              filter?.status ? translations[appLanguage]['g_filterStatus' + filter?.status] : '--'
            }
            disabled
          />
          <Input
            label={translations[appLanguage]['g427']}
            value={translations[appLanguage]['g_filterType' + filter?.type]}
            disabled
          />
          <Input label={translations[appLanguage]['g204']} value={filter?.companyName} disabled />
          <div style={{ display: 'flex' }}>
            <Input
              label={translations[appLanguage]['g517']}
              value={filter?.companyPhone}
              disabled
            />
            <a className={styles.call_now_Button} href={'tel:' + filter?.companyPhone}>
              {translations[appLanguage]['g542']}
            </a>
          </div>
          <Input label={translations[appLanguage]['g540']} value={filter?.customerName} disabled />
          {Date.now() < filter?.finishDate && <div>Filtrenin bitmesine :</div>}
          {filter?.finishDate && (
            <div>
              {Date.now() > filter?.finishDate
                ? 'Filtreniz Kirli'
                : parseInt(remainFilterTime / DAY) +
                  'Gün ,' +
                  parseInt((remainFilterTime % DAY) / HOUR) +
                  'Saat ,' +
                  parseInt((remainFilterTime % HOUR) / MINUTE) +
                  'Dakika ,' +
                  parseInt((remainFilterTime % MINUTE) / SECOND) +
                  ' Saniye kaldı'}
            </div>
          )}
          {/* <Button
          onClick={() => {
            const filterBarcode = { type, companyId };
            const url =
              user.role === userRole.ADMIN
                ? '/admin/filterBarcodes'
                : '/companyOwner/filterBarcodes';
            id
              ? updateFilterBarcode(id, filterBarcode, () => history.push(url))
              : createFilterBarcode(filterBarcode, () => history.push(url));
          }}
        >
          {id ? translations[appLanguage]['g515'] : translations[appLanguage]['g514']}
        </Button> */}
        </div>
      )}
    </div>
  );
}
