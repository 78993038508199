import { SearchOutlined } from '@ant-design/icons';
import { DatePicker, Input, Select } from 'antd';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import React, { useContext, useEffect, useState } from 'react';
import { translations } from 'resources';
import styles from './index.module.scss';

// contexts
import { CompanyContext, LanguageContext, WarehouseContext } from 'context';

const { RangePicker } = DatePicker;

let timeoutId;

export default function Search({
  companyEnabled,
  customerNameEnabled,
  cityEnabled,
  dateField,
  onChange,
  disabled,
  statusOptions,
  typeOptions,
}) {
  const [companyId, setCompanyId] = useState();
  // const [customerName, setCustomerName] = useState();
  const [city, setCity] = useState();
  const [searchText, setSearchText] = useState('');
  const [searchTextLast, setSearchTextLast] = useState('');
  const [dates, setDates] = useState([]);
  const [status, setStatus] = useState();
  const [type, setType] = useState();

  const {
    state: { appLanguage },
  } = useContext(LanguageContext);

  const {
    state: { companies },
    fetchCompanies,
  } = useContext(CompanyContext);
  // const {
  //   state: { warehouses },
  //   fetchWarehouses,
  // } = useContext(WarehouseContext);

  useEffect(() => {
    if (companyEnabled) fetchCompanies();
  }, [companyEnabled]); //eslint-disable-line

  // useEffect(() => {
  //   if (warehouseEnabled) fetchWarehouses();
  // }, [warehouseEnabled]); //eslint-disable-line

  const sendChange = () => {
    const filter = { fields: [] };
    if (searchText) filter.searchText = searchTextLast;
    if (companyId)
      filter.fields.push({ condition: 'equal', value: companyId, dataField: 'companyId' });
    // if (wareHouseId)
    //   filter.fields.push({ condition: 'equal', value: wareHouseId, dataField: 'wareHouseId' });
    if (dateField && dates?.[0])
      filter.fields.push({
        condition: 'greater_than',
        value: dates[0].toDate(),
        dataField: dateField,
      });
    if (dateField && dates?.[1])
      filter.fields.push({
        condition: 'less_than',
        value: dates[1].toDate(),
        dataField: dateField,
      });
    if (status) filter.fields.push({ condition: 'equal', value: status, dataField: 'status' });
    if (type) filter.fields.push({ condition: 'equal', value: type, dataField: 'type' });
    if (onChange) onChange(filter);
  };

  useEffect(() => {
    sendChange();
  }, [companyId, searchTextLast, dates, status, type]); //eslint-disable-line

  return (
    <div style={{ marginBottom: '8px' }}>
      <div className={styles.inputs_container}>
        <div className={styles.input_container}>
          <Input
            disabled={disabled}
            allowClear
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              clearTimeout(timeoutId);
              timeoutId = setTimeout(() => setSearchTextLast(e.target.value), 500);
            }}
            placeholder='Arama yapabilirsiniz'
            suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
          />
        </div>
        {companyEnabled && (
          <div className={styles.input_container}>
            <Select
              disabled={disabled}
              style={{ width: '100%' }}
              value={companyId}
              onChange={(value) => setCompanyId(value)}
              options={companies?.map((el) => ({ label: el.name, value: el._id, key: el._id }))}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder='Firma Seçebilirsiniz'
              allowClear
              showSearch
            />
          </div>
        )}
        {/* {warehouseEnabled && (
          <div className={styles.input_container}>
            <Select
              disabled={disabled}
              style={{ width: '100%' }}
              value={wareHouseId}
              onChange={(value) => setWareHouseId(value)}
              options={warehouses?.map((el) => ({ label: el.name, value: el._id, key: el._id }))}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder='Depo Seçebilirsiniz'
              allowClear
              showSearch
            />
          </div>
        )} */}
        {statusOptions && (
          <div className={styles.input_container}>
            <Select
              disabled={disabled}
              style={{ width: '100%' }}
              value={status}
              onChange={(value) => setStatus(value)}
              options={statusOptions?.map((el) => ({
                label: el.text,
                value: el.value,
                key: el.value,
              }))}
              placeholder={translations[appLanguage]['g434']}
              allowClear
            />
          </div>
        )}
        {typeOptions && (
          <div className={styles.input_container}>
            <Select
              disabled={disabled}
              style={{ width: '100%' }}
              value={type}
              onChange={(value) => setType(value)}
              options={typeOptions?.map((el) => ({
                label: el.text,
                value: el.value,
                key: el.value,
              }))}
              placeholder={translations[appLanguage]['g435']}
              allowClear
            />
          </div>
        )}
        {dateField && (
          <div className={styles.input_container}>
            <RangePicker
              disabled={disabled}
              locale={locale}
              value={dates}
              onChange={(value) => setDates(value)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
