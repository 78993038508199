import { apiURL, axiosClient } from 'service';
import {
  FETCH_USERS_ERROR,
  FETCH_USERS_SUCCESS,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  SET_LOADING,
} from 'context/user/keys';

export const fetchUsers = (dispatch) => async (deviceFilter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.users, deviceFilter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_USERS_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_USERS_ERROR, payload: response.error });
  }
};

export const getUser = (dispatch) => async (customerId) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.get(`${apiURL.user}/${customerId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_USER_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_USER_ERROR, payload: response.error });
  }
};

export const createUser = (dispatch) => async (user, callback) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.user, user, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_USER_SUCCESS, payload: response });
    if (callback) callback(response);
  } else {
    dispatch({ type: FETCH_USER_ERROR, payload: response.error });
  }
};

export const updateUser = (dispatch) => async (userId, user, callback) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.put(`${apiURL.user}/${userId}`, user, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_USER_SUCCESS, payload: response });
    if (callback) callback(response);
  } else {
    dispatch({ type: FETCH_USER_ERROR, payload: response.error });
  }
};
