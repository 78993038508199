import { apiURL, axiosClient } from 'service';
import { CREATE_API_SUCCESS, CREATE_API_ERROR } from 'context/api/keys';

export const createApi = (dispatch) => async (userId) => {
  const response = (
    await axiosClient.post(
      apiURL.api,
      { id: userId },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    )
  ).data;

  if (!response.error) {
    dispatch({ type: CREATE_API_SUCCESS, payload: response });
  } else {
    dispatch({ type: CREATE_API_ERROR, payload: response.error });
  }
};

export const fetchApi = (dispatch) => async (userId) => {
  const response = (
    await axiosClient.post(
      apiURL.getApi,
      { id: userId },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    )
  ).data;

  if (!response.error) {
    dispatch({ type: CREATE_API_SUCCESS, payload: response });
  } else {
    dispatch({ type: CREATE_API_ERROR, payload: response.error });
  }
};
