import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Table, Badge, Avatar } from 'antd';

// contexts
import { LanguageContext, DeviceContext, AuthContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import { getParam, getUrlPrefix } from 'helpers/page';
import history from 'history.js';

export default function HomePage(props) {
  const [allDevices, setAllDevices] = useState(0);
  const [finishedDevices, setFinishedDevices] = useState(0);
  const [day3Devices, setDay3Devices] = useState(0);
  const [day5Devices, setDay5Devices] = useState(0);
  const [day10Devices, setDay10Devices] = useState(0);
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const {
    state: { devices },
    getCountOfDevicesToChangeFilter,
    searchDevices,
  } = useContext(DeviceContext);

  const generateFields = (remainDay) => {
    return [
      {
        condition: 'custom',
        dataField: 'filters',
        value: {
          $elemMatch: {
            finishDate: { $lt: Date.now() + remainDay * 24 * 60 * 60 * 1000 },
            required: true,
          },
        },
      },
      {
        condition: 'custom',
        dataField: 'customerId',
        value: { $ne: null },
      },
      {
        condition: 'custom',
        dataField: 'deviceBarcodeId',
        value: { $ne: null },
      },
    ];
  };

  const loadCounts = async () => {
    setAllDevices(
      (
        await searchDevices({
          filter: { page: { number: 1, size: 100 } },
        })
      ).data.length
    );
    setFinishedDevices(
      (
        await searchDevices({
          filter: {
            page: { number: 1, size: 100 },
            fields: generateFields(0),
          },
        })
      ).data.length
    );
    setDay3Devices(
      (
        await searchDevices({
          filter: {
            page: { number: 1, size: 100 },
            fields: generateFields(3),
          },
        })
      ).data.length
    );
    setDay5Devices(
      (
        await searchDevices({
          filter: {
            page: { number: 1, size: 100 },
            fields: generateFields(5),
          },
        })
      ).data.length
    );
    setDay10Devices(
      (
        await searchDevices({
          filter: {
            page: { number: 1, size: 100 },
            fields: generateFields(10),
          },
        })
      ).data.length
    );
  };

  useEffect(() => {
    loadCounts();
  }, []);

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{translations[appLanguage]['g546']}</h3>
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <div>
        <div style={{ margin: '10px' }}>
          <a
            href={
              getUrlPrefix(user) +
              '/devices?pageIndex=1&pageSize=20&onlyFinishedDevices=true&remainTime=0'
            }
          >
            <Badge count={finishedDevices}>
              <div shape='square' className={styles.avatar} style={{ backgroundColor: 'red' }}>
                <h1 style={{ width: '400px' }}>{translations[appLanguage]['g545']}</h1>
              </div>
            </Badge>
          </a>
        </div>
        <div style={{ margin: '10px' }}>
          <a
            href={
              getUrlPrefix(user) +
              '/devices?pageIndex=1&pageSize=20&onlyFinishedDevices=true&remainTime=3'
            }
          >
            <Badge count={day3Devices}>
              <div
                shape='square'
                className={styles.avatar}
                style={{ backgroundColor: 'indianred' }}
              >
                <h1>{translations[appLanguage]['g565']}</h1>
              </div>
            </Badge>
          </a>
        </div>
        <div style={{ margin: '10px' }}>
          <a
            href={
              getUrlPrefix(user) +
              '/devices?pageIndex=1&pageSize=20&onlyFinishedDevices=true&remainTime=5'
            }
          >
            <Badge count={day5Devices}>
              <div shape='square' className={styles.avatar} style={{ backgroundColor: 'yellow' }}>
                <h1>{translations[appLanguage]['g566']}</h1>
              </div>
            </Badge>
          </a>
        </div>
        <div style={{ margin: '10px' }}>
          <a
            href={
              getUrlPrefix(user) +
              '/devices?pageIndex=1&pageSize=20&onlyFinishedDevices=true&remainTime=10'
            }
          >
            <Badge count={day10Devices}>
              <div
                shape='square'
                className={styles.avatar}
                style={{ backgroundColor: 'yellowgreen' }}
              >
                <h1>{translations[appLanguage]['g567']}</h1>
              </div>
            </Badge>
          </a>
        </div>
        <div style={{ margin: '10px' }}>
          <a href={getUrlPrefix(user) + '/devices'}>
            <Badge count={allDevices}>
              <div shape='square' className={styles.avatar} style={{ backgroundColor: 'green' }}>
                <h1>{translations[appLanguage]['g568']}</h1>
              </div>
            </Badge>
          </a>
        </div>
      </div>
    </div>
  );
}
