import { apiURL, axiosClient } from 'service';
import { PASSWORD_CHANGE_SUCCESS, PASSWORD_CHANGE_ERROR } from 'context/password/keys';
import history from 'history.js';

export const changePassword = (dispatch) => async (password, notify, url) => {
  const response = (
    await axiosClient.put(apiURL.passwordChange, password, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response?.error?.message) {
    dispatch({ type: PASSWORD_CHANGE_SUCCESS });
    localStorage.setItem('token', response.token);
    notify();
    history.push(url);
  } else {
    dispatch({ type: PASSWORD_CHANGE_ERROR, payload: response.error.message });
  }
};
