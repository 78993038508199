import { CREATE_API_SUCCESS, CREATE_API_ERROR } from 'context/api/keys';

export const initialState = {
  apiKey: '',
  secretKey: '',
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_API_SUCCESS:
      return {
        ...state,
        apiKey: payload.apiKey,
        secretKey: payload.secretKey,
      };
    case CREATE_API_ERROR:
      return { ...state, errorMessage: payload };
    default:
      return state;
  }
};

export default reducer;
