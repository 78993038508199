export const FETCH_DEVICE_BARCODES_SUCCESS = 'FETCH_DEVICE_BARCODES_SUCCESS';
export const FETCH_DEVICE_BARCODES_ERROR = 'FETCH_DEVICE_BARCODES_ERROR';
export const FETCH_DEVICE_BARCODE_SUCCESS = 'FETCH_DEVICE_BARCODE_SUCCESS';
export const FETCH_DEVICE_BARCODE_ERROR = 'FETCH_DEVICE_BARCODE_ERROR';
export const UPDATE_DEVICE_BARCODE_SUCCESS = 'UPDATE_DEVICE_BARCODE_SUCCESS';
export const UPDATE_DEVICE_BARCODE_ERROR = 'UPDATE_DEVICE_BARCODE_ERROR';
export const CREATE_DEVICE_BARCODE_SUCCESS = 'CREATE_DEVICE_BARCODE_SUCCESS';
export const CREATE_DEVICE_BARCODE_ERROR = 'CREATE_DEVICE_BARCODE_ERROR';
export const RESET = 'RESET';
export const SET_LOADING = 'SET_LOADING';
