import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Table, Modal } from 'antd';

// contexts
import { LanguageContext, ControlCardContext, AuthContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import { getParam } from 'helpers/page';
import Search from 'ui/components/search/search';
import Input from 'ui/reusables/input';

import history from 'history.js';
import userRole from 'resources/constants/userRole';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export default function ControlCards(props) {
  const {
    state: { user },
  } = useContext(AuthContext);
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { controlCards, loading, totalControlCardNumber },
    fetchControlCards,
    clearControlCard,
  } = useContext(ControlCardContext);

  const [calcViewHeight, setCalcViewHeight] = useState(window.innerHeight);
  const [calcViewWidth, setCalcViewWidth] = useState(window.innerWidth);
  const [visible, setVisible] = useState(false);
  const [smsModalVisible, setSmsModalVisible] = useState(false);
  const [filter, setFilter] = useState({});
  const [pageIndex, setPageIndex] = useState(getParam(props, 'pageIndex', 1));
  const [pageSize, setPageSize] = useState(getParam(props, 'pageSize', 20));

  function showConfirm(id) {
    confirm({
      title: 'Kontrol Kartı Temizleme',
      icon: <ExclamationCircleOutlined />,
      content: 'Bu kontrol kartını temizlemek istiyor musunuz?',
      async onOk() {
        await clearControlCard(id);
        window.location.reload();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const onPageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    setPageSize(newPageSize);
  };

  const getFilteredItems = (pageIndex) => {
    filter.page = { number: parseInt(pageIndex), size: parseInt(pageSize) };

    filter.sort = '-createdAt';
    fetchControlCards({ filter });
    if (
      pageIndex !== getParam(props, 'pageIndex', 1) ||
      pageSize !== getParam(props, 'pageSize', 20)
    )
      props.history.push(`${props.location.pathname}?pageIndex=${pageIndex}&pageSize=${pageSize}`);
  };

  useEffect(() => {
    onPageChange(1, pageSize);
    getFilteredItems(1);
  }, [filter]); //eslint-disable-line

  useEffect(() => {
    getFilteredItems(pageIndex);
  }, [pageIndex, pageSize]); //eslint-disable-line

  const handleModalClose = useCallback(() => {
    setVisible(false);
  }, []);

  const onResize = useCallback(() => {
    const calcViewHeight = window.innerHeight;
    const calcViewWidth = window.innerWidth;
    setCalcViewHeight(calcViewHeight);
    setCalcViewWidth(calcViewWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => onResize());
  }, [onResize]);

  const getUrlPrefix = useCallback(() => {
    if (user.role === userRole.ADMIN) return '/admin';
    else if (user.role === userRole.COMPANY_OWNER) return '/companyOwner';
    else if (user.role === userRole.COMPANY_USER) return '/companyUser';
    else if (user.role === userRole.COMPANY_ACCOUNTANT) return '/companyAccountant';
    else if (user.role === userRole.WORKER) return '/worker';
  }, [user.role]);

  const data = new Array(totalControlCardNumber);
  const newProducts = [];
  for (let i = 0; i < controlCards?.length; i++) {
    let item = controlCards[i];
    newProducts.push({
      ...item,
      key: i,
      ind: i,
      companyName: item.company?.name || '--',
      serialNumber: item.serialNumber || '--',
      hwVersion: item.hwVersion || '--',
      swVersion: item.swVersion || '--',
      deviceId: item?.device?.ID || '--',
      lastSeen: new Date(item?.lastSeen)?.toLocaleString?.() || '--',
    });
  }
  data.splice(pageSize * (pageIndex - 1), pageSize, ...newProducts);

  const columns = [
    {
      title: '#',
      dataIndex: 'no',
      key: 'no',
      width: 50,
      fixed: 'left',
      render: (e, record) => <div>{record.ind + 1}</div>,
    },
    {
      title: translations[appLanguage]['g204'],
      width: 60,
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: 'hwVersion',
      width: 60,
      dataIndex: 'hwVersion',
      key: 'hwVersion',
    },
    {
      title: 'swVersion',
      width: 60,
      dataIndex: 'swVersion',
      key: 'swVersion',
    },
    {
      title: translations[appLanguage]['g208'],
      width: 60,
      dataIndex: 'serialNumber',
      key: 'serialNumber',
    },
    {
      title: translations[appLanguage]['g458'] + ' ID',
      width: 40,
      dataIndex: 'deviceId',
      key: 'deviceId',
      render: (e, record) => (
        <a href={getUrlPrefix() + '/editDevice/' + record.device?._id}>{record.deviceId}</a>
      ),
    },
    {
      title: translations[appLanguage]['g510'],
      width: 40,
      dataIndex: 'lastSeen',
      key: 'lastSeen',
    },
    {
      title: ' ',
      dataIndex: 'edit',
      key: 'edit',
      width: 50,
      render: (e, record) => (
        <div style={{ display: 'flex' }}>
          <Button
            onClick={() =>
              user.role === userRole.ADMIN
                ? history.push('/admin/editControlCard/' + record._id)
                : history.push('/companyOwner/editControlCard/' + record._id)
            }
          >
            {translations[appLanguage][user.role === userRole.ADMIN ? 'g309' : 'g543']}
          </Button>
          {user.role === userRole.ADMIN && (
            <Button onClick={() => showConfirm(record._id)}>
              {translations[appLanguage]['g569']}
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{translations[appLanguage]['g509']}</h3>
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <Search
        onChange={setFilter}
        companyEnabled={user.role === userRole.ADMIN}
        dateField='createdAt'
      />
      <div className={styles.table_container}>
        <Table
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setVisible(true);
                // setCurrentProduct(record);
              },
            };
          }}
          size={'middle'}
          loading={loading && !data?.length}
          bordered
          columns={columns}
          dataSource={data}
          scroll={{ x: 600, y: calcViewWidth > 995 ? calcViewHeight - 310 : calcViewHeight - 260 }}
          expandable
          pagination={{ onChange: onPageChange, current: pageIndex, pageSize }}
        />
        {/* <Modal
          title='SMS yolla'
          visible={smsModalVisible}
          onOk={() => {
            setSmsModalVisible(false);
            smsCustomer(smsTitle, smsMessage, selectedCustomer.phone);
          }}
          onCancel={() => setSmsModalVisible(false)}
        >
          <Input
            label={translations[appLanguage]['g499']}
            value={smsTitle}
            onChange={(e) => setSmsTitle(e.target.value)}
          />
          <Input
            label={translations[appLanguage]['g500']}
            value={smsMessage}
            onChange={(e) => setSmsMessage(e.target.value)}
          />
        </Modal> */}
      </div>
    </div>
  );
}
