import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Table } from 'antd';

// contexts
import { LanguageContext, AuthContext, PaymentContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import { getParam } from 'helpers/page';
import Search from 'ui/components/search/search';
import history from 'history.js';
import userRole from 'resources/constants/userRole';

export default function Companies(props) {
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { payments, loading, totalPaymentNumber },
    fetchPayments,
  } = useContext(PaymentContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  const [calcViewHeight, setCalcViewHeight] = useState(window.innerHeight);
  const [calcViewWidth, setCalcViewWidth] = useState(window.innerWidth);
  const [currentProduct, setCurrentProduct] = useState({});
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState({});
  const [pageIndex, setPageIndex] = useState(getParam(props, 'pageIndex', 1));
  const [pageSize, setPageSize] = useState(getParam(props, 'pageSize', 20));

  const onPageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    setPageSize(newPageSize);
  };

  const getFilteredItems = (pageIndex) => {
    filter.page = { number: parseInt(pageIndex), size: parseInt(pageSize) };

    filter.sort = '-createdAt';
    fetchPayments({ filter });
    if (
      pageIndex !== getParam(props, 'pageIndex', 1) ||
      pageSize !== getParam(props, 'pageSize', 20)
    )
      props.history.push(`${props.location.pathname}?pageIndex=${pageIndex}&pageSize=${pageSize}`);
  };

  useEffect(() => {
    onPageChange(1, pageSize);
    getFilteredItems(1);
  }, [filter]); //eslint-disable-line

  useEffect(() => {
    getFilteredItems(pageIndex);
  }, [pageIndex, pageSize]); //eslint-disable-line

  const handleModalClose = useCallback(() => {
    setVisible(false);
  }, []);

  const onResize = useCallback(() => {
    const calcViewHeight = window.innerHeight;
    const calcViewWidth = window.innerWidth;
    setCalcViewHeight(calcViewHeight);
    setCalcViewWidth(calcViewWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => onResize());
  }, [onResize]);

  const getUrlPrefix = useCallback(() => {
    if (user.role === userRole.ADMIN) return '/admin';
    else if (user.role === userRole.COMPANY_OWNER) return '/companyOwner';
    else if (user.role === userRole.COMPANY_USER) return '/companyUser';
    else if (user.role === userRole.COMPANY_ACCOUNTANT) return '/companyAccountant';
    else if (user.role === userRole.WORKER) return '/worker';
  }, [user.role]);

  const data = new Array(totalPaymentNumber);
  const newProducts = [];
  for (let i = 0; i < payments?.length; i++) {
    let item = payments[i];
    newProducts.push({
      ind: i,
      customerName: item.customer?.firstName + item.customer.lastName,
      companyName: item.company?.name,
      ...item,
      type: translations[appLanguage]['g_paymentType' + item.type],
    });
  }
  data.splice(pageSize * (pageIndex - 1), pageSize, ...newProducts);

  const columns = [
    {
      title: '#',
      dataIndex: 'no',
      key: 'no',
      width: 50,
      fixed: 'left',
      render: (e, record) => <div>{record.ind + 1}</div>,
    },
    {
      title: translations[appLanguage]['g427'],
      width: 120,
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: translations[appLanguage]['g553'],
      width: 100,
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: translations[appLanguage]['g122'],
      width: 100,
      dataIndex: 'customerName',
      key: 'customerName',
    },
  ];

  if (user.role === userRole.ADMIN) {
    columns.push({
      title: translations[appLanguage]['g204'],
      width: 100,
      dataIndex: 'companyName',
      key: 'companyName',
    });
  }
  columns.push({
    title: ' ',
    dataIndex: 'edit',
    key: 'edit',
    width: 50,
    render: (e, record) => (
      <div style={{ display: 'flex' }}>
        <Button onClick={() => history.push(getUrlPrefix() + '/editPayment/' + record._id)}>
          Düzenle
        </Button>
      </div>
    ),
  });

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{translations[appLanguage]['g549']}</h3>
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <Search onChange={setFilter} />
      <div className={styles.table_container}>
        <Table
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setVisible(true);
                setCurrentProduct(record);
              },
            };
          }}
          size={'middle'}
          loading={loading && !data?.length}
          bordered
          columns={columns}
          dataSource={data}
          scroll={{ x: 600, y: calcViewWidth > 995 ? calcViewHeight - 310 : calcViewHeight - 260 }}
          expandable
          pagination={{ onChange: onPageChange, current: pageIndex, pageSize }}
        />
        {/* <ProductDetailDialog
          productId={currentProduct.id}
          visible={visible}
          onClose={handleModalClose}
        /> */}
      </div>
    </div>
  );
}
