import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  KeyOutlined,
  CaretRightOutlined,
  TranslationOutlined,
  InsertRowBelowOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Select } from 'antd';

import { LanguageContext, AuthContext } from 'context';
import { translations } from 'resources';
import styles from './settings-page.module.scss';

const { Option } = Select;

export default function SettingsPage() {
  const {
    state: { appLanguage },
    changeAppLanguage,
  } = useContext(LanguageContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const {
    settings_container,
    account_settings_container,
    settings_card_container,
    settings_header,
    settings_row,
    settings_row_label,
  } = styles;

  const handleChange = (value) => {
    changeAppLanguage(value);
  };

  return (
    <div className={settings_container}>
      <div className={account_settings_container}>
        <div className={settings_header}>{translations[appLanguage]['g28']}</div>
        <div className={settings_card_container}>
          <Link to='/customer/settings/password' className={settings_row}>
            <div className={settings_row_label}>
              <KeyOutlined />
              {translations[appLanguage]['g21']}
            </div>
            <CaretRightOutlined />
          </Link>

          <div className={settings_row}>
            <div className={settings_row_label}>
              <TranslationOutlined />
              {translations[appLanguage]['g29']}
            </div>
            <Select defaultValue={appLanguage} style={{ width: 120 }} onChange={handleChange}>
              <Option value='tr'>Türkçe</Option>
              <Option value='en'>English</Option>
            </Select>
          </div>
        </div>
        {/* 
        {user?.role !== 'companyUser' && (
          <>
            <div className={settings_header}>{translations[appLanguage]['g216']}</div>
            <div className={settings_card_container}>
              <Link to='/admin/settings/users' className={settings_row}>
                <div className={settings_row_label}>
                  <UnorderedListOutlined />
                  {translations[appLanguage]['g194']}
                </div>
                <CaretRightOutlined />
              </Link>

              <Link to='/admin/settings/users/add' className={settings_row}>
                <div className={settings_row_label}>
                  <InsertRowBelowOutlined />
                  {translations[appLanguage]['g192']}
                </div>
                <CaretRightOutlined />
              </Link>
            </div>
          </>
        )} */}

        {/* <div className={settings_header}>{translations[appLanguage]['g159']}</div>
        <div className={settings_card_container}>
          <Link to='/admin/settings/company' className={settings_row}>
            <div className={settings_row_label}>
              <UnorderedListOutlined />
              {translations[appLanguage]['g156']}
            </div>
            <CaretRightOutlined />
          </Link>
        </div> */}
      </div>
    </div>
  );
}
