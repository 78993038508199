export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANY_NAMES_SUCCESS = 'FETCH_COMPANY_NAMES_SUCCESS';
export const FETCH_COMPANIES_ERROR = 'FETCH_COMPANIES_ERROR';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_ERROR = 'ADD_COMPANY_ERROR';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'FETCH_COMPANY_ERROR';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_ERROR = 'UPDATE_COMPANY_ERROR';
export const RESET_COMPANY = 'RESET_COMPANY';
export const SET_LOADING = 'SET_LOADING';
export const REQUEST_ERROR = 'REQUEST_ERROR';
