import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

// helpers
import { translations } from 'resources';

// contexts
import { LanguageContext } from 'context';

// import history from 'history.js';

// styles
import logo from 'resources/images/blended-logo.png';
import styles from './landing-page.module.scss';

const languageOptions = [
  { key: 'Türkçe', value: 'tr', text: 'Türkçe' },
  { key: 'English', value: 'en', text: 'English' },
];

export default function LandingPage() {
  const {
    state: { appLanguage },
    changeAppLanguage,
  } = useContext(LanguageContext);

  return (
    <div className={styles.landing_page_container}>
      <div className={styles.content_container}>
        <img className={styles.logo} src={logo} alt='logo' />

        <div className={styles.signup_text_container}>
          <Link to='/signin' className={styles.signup_text}>
            {translations[appLanguage]['g6']}
          </Link>
        </div>

        <div className={styles.signin_text_container}>
          <div className={styles.signin_text}>TCTEGE</div>
          <div className={styles.seperator} />
          <select
            value={appLanguage}
            onChange={(e) => changeAppLanguage(e.target.value)}
            className={styles.select}
          >
            {languageOptions.map(({ text, value, key }) => (
              <option key={key} value={value}>
                {text}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
