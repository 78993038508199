import BrowserPrint from './BrowserPrint-3.0.216.min.js';
let selectedDevice;
let devices = [];

let errorCallback = (errorMessage) => {
  // alert('Error: ' + errorMessage);
};

export const loadPrinter = () => {
  //Get the default device from the application as a first step. Discovery takes longer to complete.
  BrowserPrint.getDefaultDevice(
    'printer',
    function (device) {
      //Add device to list of devices and to html select element
      console.log('device set');
      selectedDevice = device;
      console.log('device', device);
      // zebraPrinter = new Zebra.Printer(device);
      devices.push(device);
      //eslint-disable-next-line
      var html_select = document.getElementById('selected_device');
      var option = document.createElement('option');
      option.text = device.name;
      // html_select.add(option);

      //Discover any other devices available to the application
      // BrowserPrint.getLocalDevices(
      //   function (deviceList) {
      //     for (var i = 0; i < deviceList.length; i++) {
      //       //Add device to list of devices and to html select element
      //       var device = deviceList[i]; //eslint-disable-next-line
      //       if (!selectedDevice || device.uid != selectedDevice.uid) {
      //         devices.push(device);
      //         var option = document.createElement('option');
      //         option.text = device.name;
      //         option.value = device.uid;
      //         html_select.add(option);
      //       }
      //     }
      //   },
      //   function () {
      //     // alert('Error getting local devices');
      //   },
      //   'printer'
      // );
    },
    function (error) {
      // alert(error);
    }
  );
};

export const getConfig = () => {
  BrowserPrint.getApplicationConfiguration(
    function (config) {
      // alert(JSON.stringify(config));
    },
    function (error) {
      // alert(JSON.stringify(new BrowserPrint.ApplicationConfiguration()));
    }
  );
};

export const getPrinters = async () => {
  return new Promise((res, rej) => {
    BrowserPrint.getLocalDevices(
      function (deviceList) {
        deviceList = deviceList.map((device, i) => {
          let sameDevices = 0;
          for (let j = 0; j < i; j++)
            if (device.uid === deviceList[j].uid) {
              sameDevices++;
            }
          if (sameDevices) device.uid = device.uid + '-' + (sameDevices + 1);

          return device;
        });
        res(deviceList);
      },
      function () {
        res([]);
      },
      'printer'
    );
  });
};

export const getPrinter = async (printerUid) => {
  const printers = await getPrinters();
  return printers.find((el) => el.uid === printerUid);
};

export const sendImage = (imageUrl, device) => {
  (device || selectedDevice).convertAndSendFile(
    imageUrl,
    () => console.log('callback'),
    errorCallback
  );
};

export const sendFile = (imageUrl, device) => {
  (device || selectedDevice).sendFile(imageUrl, () => console.log('callback'), errorCallback);
};

export const sendZpl = (zpl, device) => {
  console.log('zpl', zpl);
  zpl = zpl.replaceAll('\\r', '\r');
  zpl = zpl.replaceAll('\\n', '\n');
  // for (let a = 0; a < 1000; a++) zpl = zpl.replace('\\r', '\r');
  // for (let a = 0; a < 1000; a++) zpl = zpl.replace('\\n', '\n');

  // console.log('zpl2', zpl);
  (device || selectedDevice).send(zpl, undefined, errorCallback);
};

export const sendImageWithBase = (imageUrl) => {
  let url = window.location.href.substring(0, window.location.href.lastIndexOf('/'));
  url = url + '/' + imageUrl;
  selectedDevice.convertAndSendFile(url, undefined, errorCallback);
};

export const writeZPL = (dataToWrite) => {
  selectedDevice.send(dataToWrite, undefined, errorCallback);
};

export const writeBarcode = (barcode) => {
  barcode = 'ASAS';
  console.log('writeBarcode', barcode);
  writeZPL('^XA^FO50,50^BY3^BCN,100,Y,N,N^FD>;' + barcode + '3^FS^XZ');
};
