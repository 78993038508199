import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

// admin-pages
// import Home from 'ui/pages/admin-pages/home';
import Devices from 'ui/pages/devices';
import AddDevice from 'ui/pages/addDevice';
import EditDevice from 'ui/pages/editDevice';
import AssemblyDevice from 'ui/pages/assemblyDevice';
import Customers from 'ui/pages/customers';
import Users from 'ui/pages/users';
import AddCustomer from 'ui/pages/addCustomer';
import ControlCards from 'ui/pages/controlCards';
import AddUser from 'ui/pages/addUser';
import Companies from 'ui/pages/companies';
import AddCompany from 'ui/pages/editCompany';
import FilterBarcodes from 'ui/pages/filterBarcodes';
import AddFilterBarcode from 'ui/pages/addFilterBarcode';
import PasswordChange from 'ui/pages/settings/password-change';
import SettingsPage from 'ui/pages/settings/settings-page';
import EditControlCard from 'ui/pages/editControlCard/';
import CheckFilterBarcode from 'ui/pages/checkFilterBarcode/';
import CheckDeviceBarcode from 'ui/pages/checkDeviceBarcode/';
import DeviceBarcodes from 'ui/pages/deviceBarcodes';
import AddDeviceBarcode from 'ui/pages/addDeviceBarcode';

// components
import Sidebar from 'ui/components/sidebar';
import Topbar from 'ui/components/topbar';

// contexts
import { LanguageContext, AuthContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import {
  HomeOutlined,
  SettingOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined,
  InsertRowBelowOutlined,
  ImportOutlined,
  InboxOutlined,
  ShopOutlined,
  TeamOutlined,
  FileTextOutlined,
  UserOutlined,
  RobotOutlined,
  UserAddOutlined,
  UnorderedListOutlined,
  BankOutlined,
  PlusCircleOutlined,
  BoxPlotOutlined,
  ToolOutlined,
  InsertRowLeftOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import styles from './index.module.scss';
import payments from '../payments';
import addPayment from '../addPayment';
import HomePage from '../homePage';
import customerDetail from '../customerDetail';
import { companyAccountantMenu } from 'helpers/menu';

const urlPrefix = '/companyAccountant';

const CompanyAccountantPages = () => {
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  const sideBarItems = companyAccountantMenu(appLanguage);

  return (
    <>
      <Topbar />
      <div className={styles.main_content_container}>
        <Sidebar items={sideBarItems} />
        <Route path={'/home'} exact component={HomePage} />
        <Route path={urlPrefix + '/devices'} exact component={Devices} />
        <Route path={urlPrefix + '/assemblyDevice'} exact component={AssemblyDevice} />
        <Route path={urlPrefix + '/addDevice'} exact component={AddDevice} />
        <Route path={urlPrefix + '/editDevice/:id'} exact component={EditDevice} />

        <Route path={urlPrefix + '/deviceBarcodes'} exact component={DeviceBarcodes} />
        <Route path={urlPrefix + '/editDeviceBarcode/:id'} exact component={AddDeviceBarcode} />
        <Route path={urlPrefix + '/filterBarcodes'} exact component={FilterBarcodes} />
        <Route path={urlPrefix + '/editFilterBarcode/:id'} exact component={AddFilterBarcode} />

        <Route path={urlPrefix + '/payments'} exact component={payments} />
        <Route path={urlPrefix + '/addPayment'} exact component={addPayment} />
        <Route path={urlPrefix + '/editPayment/:id'} exact component={addPayment} />

        <Route path={urlPrefix + '/customers'} exact component={Customers} />
        <Route path={urlPrefix + '/addCustomer'} exact component={AddCustomer} />
        <Route path={urlPrefix + '/editCustomer/:id'} exact component={AddCustomer} />
        <Route path={urlPrefix + '/customerDetail/:id'} exact component={customerDetail} />

        <Route path={urlPrefix + '/settings'} exact component={SettingsPage} />
        <Route path={urlPrefix + '/settings/password'} exact component={PasswordChange} />
        <Route path={urlPrefix + '/editControlCard/:id'} exact component={EditControlCard} />
        <Route path={urlPrefix + '/controlCards'} exact component={ControlCards} />

        <Route path={'/checkFilter/:id'} exact component={CheckFilterBarcode} />
        <Route path={'/checkDevice/:id'} exact component={CheckDeviceBarcode} />
      </div>
    </>
  );
};

export default CompanyAccountantPages;
