import {
  FETCH_DEVICES_ERROR,
  FETCH_DEVICES_SUCCESS,
  FETCH_DEVICE_ERROR,
  FETCH_DEVICE_SUCCESS,
  SET_LOADING,
} from 'context/device/keys';

export const initialState = {
  devices: [],
  errorMessage: '',
  device: null,
  totalDeviceNumber: 0,
  totalCount: 0,
  loading: true,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DEVICES_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        devices: [...payload.data],
        totalDeviceNumber: payload.totalCount,
        loading: false,
      };
    case FETCH_DEVICES_ERROR:
      return { ...state, errorMessage: payload, loading: false };
    case FETCH_DEVICE_SUCCESS:
      return { ...state, errorMessage: '', device: payload, loading: false };
    case FETCH_DEVICE_ERROR:
      return { ...state, errorMessage: payload };
    default:
      return state;
  }
};

export default reducer;
