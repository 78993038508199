import { apiURL, axiosClient } from 'service';
import {
  FETCH_CONTROL_CARDS_ERROR,
  FETCH_CONTROL_CARDS_SUCCESS,
  FETCH_CONTROL_CARD_ERROR,
  FETCH_CONTROL_CARD_SUCCESS,
  SET_LOADING,
  UPDATE_CONTROL_CARD_ERROR,
  UPDATE_CONTROL_CARD_SUCCESS,
} from 'context/controlCard/keys';
import history from 'history.js';

export const fetchControlCards = (dispatch) => async (filter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.controlCards, filter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_CONTROL_CARDS_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_CONTROL_CARDS_ERROR, payload: response.error });
  }
};

export const searchControlCards = (dispatch) => async (filter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.controlCards, filter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  return response;
};

export const clearControlCard = (dispatch) => async (id) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(
      apiURL.clearControlCard,
      { id },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    )
  ).data;

  return response;
};

export const getControlCard = (dispatch) => async (deviceId) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.get(`${apiURL.controlCard}/${deviceId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_CONTROL_CARD_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_CONTROL_CARD_ERROR, payload: response.error });
  }
};

export const updateControlCard = (dispatch) => async (id, controlCard) => {
  const response = (
    await axiosClient.put(`${apiURL.controlCard}/${id}`, controlCard, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: UPDATE_CONTROL_CARD_SUCCESS, payload: response });
    // history.push('/admin/companies');
  } else {
    dispatch({ type: UPDATE_CONTROL_CARD_ERROR, payload: response.error });
  }
};
