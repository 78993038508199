import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '@ant-design/icons';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';

import styles from './dropdown.module.scss';

export default function Dropdown({ menuIcon, menuName, menuItems }) {
  const [dropdownMenuVisible, setDropdownMenuVisible] = useState(false);

  return (
    <>
      <div
        onClick={() => setDropdownMenuVisible(!dropdownMenuVisible)}
        className={styles.dropdown_menu_container}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon component={menuIcon} className={styles.icon} />
          <span>{menuName}</span>
        </div>
        {!dropdownMenuVisible ? (
          <CaretRightOutlined className={styles.icon} />
        ) : (
          <CaretDownOutlined className={styles.icon} />
        )}
      </div>
      {dropdownMenuVisible && (
        <div className={styles.dropdown_menu_list_container}>
          {menuItems.map((menuItem, index) => {
            return (
              <NavLink key={index} to={menuItem.url} activeStyle={{ color: '#04798f' }}>
                <Icon component={menuItem.icon} className={styles.icon} />
                <span>{menuItem.name}</span>
              </NavLink>
            );
          })}
        </div>
      )}
    </>
  );
}
