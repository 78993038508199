import {
  FETCH_CUSTOMERS_ERROR,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMER_ERROR,
  FETCH_CUSTOMER_SUCCESS,
  SET_LOADING,
} from 'context/customer/keys';

export const initialState = {
  customers: [],
  errorMessage: '',
  customer: {},
  totalCustomerNumber: 0,
  totalCount: 0,
  loading: true,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        customers: [...payload.data],
        totalCustomerNumber: payload.totalCount,
        loading: false,
      };
    case FETCH_CUSTOMERS_ERROR:
      return { ...state, errorMessage: payload };
    case FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        customer: payload,
        loading: false,
      };
    case FETCH_CUSTOMER_ERROR:
      return { ...state, errorMessage: payload };
    default:
      return state;
  }
};

export default reducer;
