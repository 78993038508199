import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Checkbox, Select, Table } from 'antd';

// contexts
import { LanguageContext, UserContext, CompanyContext, AuthContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import Input from 'ui/reusables/input';
import { getParam } from 'helpers/page';
import Search from 'ui/components/search/search';
import history from 'history.js';
import userRole from 'resources/constants/userRole';

const { Option } = Select;
export default function EditUser(props) {
  const { id } = props.match.params;
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { user, loading },
    createUser,
    getUser,
    updateUser,
  } = useContext(UserContext);
  const {
    state: { user: myUser },
  } = useContext(AuthContext);

  const {
    state: { companies },
    fetchCompanies,
  } = useContext(CompanyContext);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVerify, setPasswordVerify] = useState('');
  const [changePassword, setChangePassword] = useState(false);
  const [companyId, setCompanyId] = useState();

  useEffect(() => {
    if (id) getUser(id);
  }, [id]); //eslint-disable-line
  useEffect(() => {
    fetchCompanies();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (id && user?._id === id) {
      setFirstName(user?.profile?.firstName);
      setLastName(user?.profile?.lastName);
      setEmail(user.email);
      setPhone(user.phone);
      setCompanyId(user.companyId);
      setRole(user.role);
    }
  }, [user]); //eslint-disable-line
  const editMode = !!id;
  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{editMode ? translations[appLanguage]['g506'] : translations[appLanguage]['g505']}</h3>
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <div style={{ width: '300px' }}>
        <Input
          label={translations[appLanguage]['g14']}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          label={translations[appLanguage]['g15']}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Input label='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
        <Input
          label={translations[appLanguage]['g1']}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />

        {translations[appLanguage]['g181']}
        <Select
          style={{ display: 'flex', flex: 1, margin: '12px 0' }}
          value={role}
          onChange={(e) => setRole(e)}
        >
          {myUser.role === userRole.ADMIN
            ? [userRole.ADMIN, userRole.COMPANY_OWNER].map((el, index) => {
                return (
                  <Option key={index} value={el}>
                    {translations[appLanguage]['g_' + el]}
                  </Option>
                );
              })
            : [userRole.COMPANY_OWNER, userRole.COMPANY_USER, userRole.COMPANY_ACCOUNTANT].map(
                (el, index) => {
                  return (
                    <Option key={index} value={el}>
                      {translations[appLanguage]['g_' + el]}
                    </Option>
                  );
                }
              )}
        </Select>
        {myUser.role === userRole.ADMIN && (
          <>
            {translations[appLanguage]['g204']}
            <Select
              style={{ display: 'flex', flex: 1, margin: '12px 0' }}
              value={companyId}
              onChange={(e) => setCompanyId(e)}
            >
              {companies?.map((el, index) => {
                return (
                  <Option key={index} value={el._id}>
                    {el?.name}
                  </Option>
                );
              })}
            </Select>
          </>
        )}
        {editMode && (
          <Checkbox checked={changePassword} onChange={(e) => setChangePassword(e.target.checked)}>
            {translations[appLanguage]['g21']}
          </Checkbox>
        )}
        {(!editMode || changePassword) && (
          <>
            <Input
              type='password'
              label={translations[appLanguage]['g4']}
              value={changePassword || !editMode ? password : ''}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Input
              type='password'
              label={translations[appLanguage]['g20']}
              value={changePassword || !editMode ? passwordVerify : ''}
              onChange={(e) => setPasswordVerify(e.target.value)}
            />
          </>
        )}
        <Button
          disabled={
            changePassword || !editMode
              ? !password || !passwordVerify || password !== passwordVerify
              : false
          }
          onClick={() => {
            const user = { profile: { firstName, lastName }, email, phone, companyId, role };
            if (changePassword || !editMode) user.password = password;
            id
              ? updateUser(id, user, () => history.push('/admin/users'))
              : createUser(user, () => history.push('/admin/users'));
          }}
        >
          {id ? translations[appLanguage]['g496'] : translations[appLanguage]['g495']}
        </Button>
      </div>
      {/* <Search onChange={setFilter} />
      <div className={styles.table_container}>
        <Table
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setVisible(true);
                setCurrentProduct(record);
              },
            };
          }}
          size={'middle'}
          loading={loading && !data?.length}
          bordered
          columns={columns}
          dataSource={data}
          scroll={{ x: 600, y: calcViewWidth > 995 ? calcViewHeight - 310 : calcViewHeight - 260 }}
          expandable
          pagination={{ onChange: onPageChange, current: pageIndex, pageSize }}
        />
      </div> */}
    </div>
  );
}
