import React from 'react';

import styles from './input.module.scss';

export default function Input({
  style,
  className,
  forwardRef,
  value,
  type,
  label,
  onChange,
  placeholder,
  onKeyPress,
  inputClassName,
  labelClassName,
  max,
  min,
  disabled,
}) {
  return (
    <div className={`${styles.input_container} ${className}`} style={style}>
      <div className={`${styles.label} ${labelClassName}`}>{label}</div>
      <input
        style={{
          backgroundColor: disabled && 'whitesmoke',
          cursor: disabled && 'not-allowed',
        }}
        disabled={disabled}
        maxLength={max}
        minLength={min}
        className={`${styles.input} ${inputClassName}`}
        ref={forwardRef}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
      />
    </div>
  );
}
