import { apiURL, axiosClient } from 'service';
import {
  FETCH_DEVICES_ERROR,
  FETCH_DEVICES_SUCCESS,
  FETCH_DEVICE_ERROR,
  FETCH_DEVICE_SUCCESS,
  SET_LOADING,
} from 'context/device/keys';
import { deviceStatus } from 'resources/constants/deviceStatus';

export const fetchDevices = (dispatch) => async (deviceFilter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.devices, deviceFilter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_DEVICES_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_DEVICES_ERROR, payload: response.error });
  }
};

export const getCountOfDevicesToChangeFilter = (dispatch) => async (deviceFilter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(
      apiURL.devices,
      {
        filter: {
          page: { number: 1, size: 1 },
          fields: [{ condition: 'equal', value: deviceStatus.ASSEMBLED, dataField: 'status' }],
        },
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    )
  ).data;

  console.log(response);
};

export const searchDevices = (dispatch) => async (deviceFilter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.devices, deviceFilter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    return response;
  } else {
    return;
  }
};
export const createBarcodeExcel = (dispatch) => async (deviceId) => {
  const response = (
    await axiosClient.post(
      apiURL.createBarcodeExcel,
      { id: deviceId },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    )
  ).data;

  if (!response.error) {
    return response;
  } else {
    return;
  }
};

export const getDevice = (dispatch) => async (deviceId) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.get(`${apiURL.device}/${deviceId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_DEVICE_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_DEVICE_ERROR, payload: response.error });
  }
};

export const deleteDevice = (dispatch) => async (deviceId) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.delete(`${apiURL.device}/${deviceId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_DEVICE_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_DEVICE_ERROR, payload: response.error });
  }
};


export const resetDevice = (dispatch) => async () => {
  dispatch({ type: FETCH_DEVICE_SUCCESS, payload: null });
};

export const createDevice = (dispatch) => async (device, callback) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(`${apiURL.createDevice}`, device, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    if (callback) callback(response.data);
  } else {
    if (callback) callback(undefined, response.error);
  }
};

export const editDevice = (dispatch) => async (deviceId, device, callback) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(`${apiURL.editDevice}/${deviceId}`, device, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    if (callback) callback(response.data);
  } else {
    if (callback) callback(undefined, response.error);
  }
};



export const editAssembledDevice = (dispatch) => async (deviceId, device, callback) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(`${apiURL.editAssembledDevice}/${deviceId}`, device, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    if (callback) callback(response.data);
  } else {
    if (callback) callback(undefined, response.error);
  }
};

export const assemblyDevice = (dispatch) => async (deviceId, callback) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(
      `${apiURL.assemblyDevice}/${deviceId}`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    )
  ).data;

  if (!response.error) {
    if (callback) callback(response.data);
  } else {
    if (callback) callback(undefined, response.error);
  }
};
