import {
  FETCH_CONTROL_CARDS_ERROR,
  FETCH_CONTROL_CARDS_SUCCESS,
  FETCH_CONTROL_CARD_ERROR,
  FETCH_CONTROL_CARD_SUCCESS,
  SET_LOADING,
  UPDATE_CONTROL_CARD_ERROR,
  UPDATE_CONTROL_CARD_SUCCESS,
} from 'context/controlCard/keys';

export const initialState = {
  controlCards: [],
  errorMessage: '',
  controlCard: {},
  totalControlCardNumber: 0,
  totalCount: 0,
  loading: true,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_CONTROL_CARDS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        controlCards: [...payload.data],
        totalControlCardNumber: payload.totalCount,
        loading: false,
      };
    case FETCH_CONTROL_CARDS_ERROR:
      return { ...state, errorMessage: payload, loading: false };
    case FETCH_CONTROL_CARD_SUCCESS:
      return { ...state, errorMessage: '', controlCard: payload, loading: false };
    case FETCH_CONTROL_CARD_ERROR:
      return { ...state, errorMessage: payload };
    case UPDATE_CONTROL_CARD_SUCCESS:
      return { ...state, errorMessage: '', controlCard: payload?.data };
    case UPDATE_CONTROL_CARD_ERROR:
      return { ...state, errorMessage: payload };
    default:
      return state;
  }
};

export default reducer;
