import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Checkbox, InputNumber, Table, Modal } from 'antd';

// contexts
import { LanguageContext, DeviceContext, AuthContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './devices.module.scss';
import { getParam } from 'helpers/page';
import Search from 'ui/components/search/search';
import userRole from 'resources/constants/userRole';
import history from 'history.js';
import { deviceStatus } from 'resources/constants/deviceStatus';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export default function Devices(props) {
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { devices, loading, totalDeviceNumber },
    fetchDevices,
    deleteDevice,
  } = useContext(DeviceContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  const [calcViewHeight, setCalcViewHeight] = useState(window.innerHeight);
  const [calcViewWidth, setCalcViewWidth] = useState(window.innerWidth);
  const [currentProduct, setCurrentProduct] = useState({});
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState({ fields: [] });
  const [pageIndex, setPageIndex] = useState(getParam(props, 'pageIndex', 1));
  const [pageSize, setPageSize] = useState(getParam(props, 'pageSize', 20));
  const [onlyFinishedDevices, setOnlyFinishedDevices] = useState(
    getParam(props, 'onlyFinishedDevices', false) === 'true'
  );
  const [remainTime, setRemainTime] = useState(parseFloat(getParam(props, 'remainTime', 0)));

  const onPageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    setPageSize(newPageSize);
  };

  function showConfirm(id) {
    confirm({
      title: 'Cihaz Silme',
      icon: <ExclamationCircleOutlined />,
      content: 'Bu cihazı silmek istiyor musunuz?',
      async onOk() {
        await deleteDevice(id);
        window.location.reload();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const getFilteredProducts = (pageIndex) => {
    filter.page = { number: parseInt(pageIndex), size: parseInt(pageSize) };
    let newFilter = filter;
    filter.sort = '-createdAt';
    if (onlyFinishedDevices)
      newFilter = {
        ...filter,
        fields: [
          ...filter.fields,
          {
            condition: 'custom',
            dataField: 'filters',
            value: {
              $elemMatch: {
                finishDate: { $lt: Date.now() + remainTime * 24 * 60 * 60 * 1000 },
                required: true,
              },
            },
          },
          {
            condition: 'custom',
            dataField: 'customerId',
            value: { $ne: null },
          },
          {
            condition: 'custom',
            dataField: 'deviceBarcodeId',
            value: { $ne: null },
          },
        ],
      };
    fetchDevices({ filter: newFilter });
    // if (
    //   pageIndex !== getParam(props, 'pageIndex', 0) ||
    //   pageSize !== getParam(props, 'pageSize', 0)
    // )
    props.history.push(
      `${props.location.pathname}?pageIndex=${pageIndex}&pageSize=${pageSize}&onlyFinishedDevices=${onlyFinishedDevices}&remainTime=${remainTime}`
    );
  };

  useEffect(() => {
    onPageChange(1, pageSize);
    getFilteredProducts(1);
  }, [filter, onlyFinishedDevices, remainTime]); //eslint-disable-line

  useEffect(() => {
    getFilteredProducts(pageIndex);
  }, [pageIndex, pageSize]); //eslint-disable-line

  const handleModalClose = useCallback(() => {
    setVisible(false);
  }, []);

  const onResize = useCallback(() => {
    const calcViewHeight = window.innerHeight;
    const calcViewWidth = window.innerWidth;
    setCalcViewHeight(calcViewHeight);
    setCalcViewWidth(calcViewWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => onResize());
  }, [onResize]);

  const getUrlPrefix = useCallback(() => {
    if (user.role === userRole.ADMIN) return '/admin';
    else if (user.role === userRole.COMPANY_OWNER) return '/companyOwner';
    else if (user.role === userRole.COMPANY_USER) return '/companyUser';
    else if (user.role === userRole.COMPANY_ACCOUNTANT) return '/companyAccountant';
    else if (user.role === userRole.WORKER) return '/worker';
  }, [user.role]);

  const data = new Array(totalDeviceNumber);
  const newProducts = [];
  for (let i = 0; i < devices.length; i++) {
    let item = devices[i];
    const filters = item.filters?.filter?.((el) => el.required) || [];
    newProducts.push({
      ind: i,
      barcode: item.deviceBarcode?.barcode,
      startedAt: item.deviceBarcode?.startedAt,
      companyName: item.company?.name,
      filterCounts: filters.filter((el) => el.isActive).length + '/' + filters.length,
      cardSerial: item.controlCard?.serialNumber,
      city: item.customer?.city,
      district: item.customer?.district,
      customerName: item.customer
        ? item.customer?.firstName + ' ' + item.customer?.lastName
        : '---',
      ...item,
    });
  }
  data.splice(pageSize * (pageIndex - 1), pageSize, ...newProducts);

  const columns = [
    {
      title: '#',
      dataIndex: 'no',
      key: 'no',
      width: 40,
      fixed: 'left',
      render: (e, record) => <div>{record.ind + 1}</div>,
    },
    {
      title: 'ID',
      width: 80,
      dataIndex: 'ID',
      key: 'ID',
    },
    {
      title: 'Barcode',
      width: 80,
      dataIndex: 'barcode',
      key: 'barcode',
    },
    {
      title: translations[appLanguage]['g557'],
      width: 80,
      dataIndex: 'cardSerial',
      key: 'cardSerial',
    },
    {
      title: translations[appLanguage]['g519'],
      width: 100,
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: 'filter',
      width: 60,
      dataIndex: 'filterCounts',
      key: 'filterCounts',
    },
    {
      title: translations[appLanguage]['g555'],
      width: 100,
      dataIndex: 'district',
      key: 'district',
    },
    {
      title: translations[appLanguage]['g177'],
      width: 100,
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: translations[appLanguage]['g544'],
      width: 70,
      dataIndex: 'deviceStatus',
      key: 'deviceStatus',
      render: (e, record) => (
        <div>{translations[appLanguage]['g_deviceStatus' + record?.status]}</div>
      ),
    },
    {
      title: translations[appLanguage]['g207'],
      width: 60,
      dataIndex: 'status',
      key: 'status',
      render: (e, record) => (
        <div>
          {record.isActive ? translations[appLanguage]['g352'] : translations[appLanguage]['g353']}
        </div>
      ),
    },
    {
      title: translations[appLanguage]['g204'],
      width: 100,
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: translations[appLanguage]['g122'],
      width: 100,
      dataIndex: 'customerName',
      key: 'customerName',
      render: (e, record) =>
        record.customer ? (
          <a href={getUrlPrefix() + '/customerDetail/' + record.customer?._id}>
            {record.customerName}
          </a>
        ) : (
          '--'
        ),
    },
    {
      title: translations[appLanguage]['g564'],
      width: 50,
      key: 'remainDay',
      render: (e, record) => {
        if (
          !record.filters?.filter?.((el) => el.required)?.length ||
          record.status !== deviceStatus.ASSEMBLED
        )
          return '--';
        else {
          let minDay = 10000 * (24 * 60 * 60 * 1000);
          const requiredFilters = record.filters?.filter?.((el) => el.required === true);
          for (const filter of requiredFilters) {
            const remainDay = filter.finishDate - Date.now();
            if (remainDay < 0) minDay = 0;
            else if (remainDay < minDay) minDay = remainDay;
          }
          minDay = parseInt(minDay / (24 * 60 * 60 * 1000));
          if (minDay <= 0) minDay = 'Bitti';
          return minDay;
        }
      },
    },
    {
      title: translations[appLanguage]['g556'],
      width: 100,
      dataIndex: 'startedAt',
      key: 'startedAt',
      render: (e, record) => <div>{new Date(record.startedAt)?.toLocaleDateString()}</div>,
    },
    {
      title: ' ',
      dataIndex: 'edit',
      key: 'edit',
      width: 70,
      render: (e, record) => (
        <div style={{ display: 'flex' }}>
          <Button onClick={() => history.push(getUrlPrefix() + '/editDevice/' + record._id)}>
            {translations[appLanguage]['g309']}
          </Button>
          {user.role === userRole.ADMIN && (
            <Button onClick={() => showConfirm(record._id)}>
              {translations[appLanguage]['g165']}
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{translations[appLanguage]['g412']}</h3>
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <Search
        onChange={setFilter}
        companyEnabled={user.role === userRole.ADMIN}
        dateField='createdAt'
        statusOptions={Object.keys(deviceStatus).map((el) => ({
          text: translations[appLanguage]['g_deviceStatus' + deviceStatus[el]],
          value: deviceStatus[el],
        }))}
      />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Checkbox
          checked={onlyFinishedDevices}
          onChange={(e) => setOnlyFinishedDevices(e.target.checked)}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>Sadece Süresi Biten ya da</div>
        </Checkbox>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <InputNumber
            disabled={!onlyFinishedDevices}
            style={{ margin: '5px', width: '70px' }}
            value={remainTime}
            onChange={setRemainTime}
            onClick={(e) => e.stopPropagation()}
            min={0}
            max={100}
          />
          <div>Gün Kalan Cihazlar</div>
        </div>
      </div>

      <div className={styles.table_container}>
        <Table
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setVisible(true);
                setCurrentProduct(record);
              },
            };
          }}
          size={'middle'}
          loading={loading && !data?.length}
          bordered
          columns={columns}
          dataSource={data}
          scroll={{ x: 600, y: calcViewWidth > 995 ? calcViewHeight - 310 : calcViewHeight - 260 }}
          expandable
          pagination={{ onChange: onPageChange, current: pageIndex, pageSize }}
        />
        {/* <ProductDetailDialog
          productId={currentProduct.id}
          visible={visible}
          onClose={handleModalClose}
        /> */}
      </div>
    </div>
  );
}
