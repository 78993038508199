import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Checkbox, Radio, Select, Table, Input as AntdInput } from 'antd';

// contexts
import {
  LanguageContext,
  DeviceContext,
  CompanyContext,
  AuthContext,
  CustomerContext,
  ControlCardContext,
  FilterBarcodeContext,
  DeviceBarcodeContext,
} from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import Input from 'ui/reusables/input';
import { getParam } from 'helpers/page';
import Search from 'ui/components/search/search';
import history from 'history.js';
import userRole from 'resources/constants/userRole';
import { filterStatus, filterType } from 'resources/constants/filterType';

const { Option } = Select;
export default function AssemblyDevice(props) {
  const [id, setId] = useState('');
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { device },
    getDevice,
    resetDevice,
    searchDevices,
    assemblyDevice,
  } = useContext(DeviceContext);

  const {
    state: { user },
  } = useContext(AuthContext);

  useEffect(() => {}, [device]); //eslint-disable-line

  useEffect(() => {
    resetDevice(id);
  }, []); //eslint-disable-line

  const options = [
    { label: '10  ' + translations[appLanguage]['g525'], value: 1000 * 60 * 60 * 24 * 10 },
    { label: '1  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 1 },
    { label: '2  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 2 },
    { label: '3  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 3 },
    { label: '4  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 4 },
    { label: '5  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 5 },
    { label: '6  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 6 },
    { label: '7  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 7 },
    { label: '8  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 8 },
    { label: '9  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 9 },
    { label: '10  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 10 },
    { label: '12  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 12 },
    { label: '18  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 18 },
    { label: '24  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 24 },
  ];

  const renderDevice = () => {
    return (
      <>
        <div style={{ display: 'flex', fontWeight: '800', fontSize: 'large' }}>
          {translations[appLanguage]['g522']}:
          <div>{device?.customer?.firstName + ' ' + device?.customer?.lastName}</div>
        </div>
        <div style={{ display: 'flex', fontWeight: '800', fontSize: 'large' }}>
          {translations[appLanguage]['g523']}:<div>{device?.controlCard?.serialNumber}</div>
        </div>
        <div style={{ display: 'flex', fontWeight: '800', fontSize: 'large' }}>
          {translations[appLanguage]['g529']}:<div>{device?.deviceBarcode?.barcode}</div>
        </div>
        <div style={{ display: 'flex', fontWeight: '800', fontSize: 'large' }}>
          {translations[appLanguage]['g204']}:<div>{device?.company?.name}</div>
        </div>

        <div> {translations[appLanguage]['g521']}</div>
        {device?.filters
          ?.filter((el) => el.required)
          .map((el) => {
            return (
              <div>
                <div> {translations[appLanguage]['g_filterType' + el.type] + ':'}</div>
                {/* {device?.deviceBarcodeId && (
                    <div>{}</div>
                  )} */}
                {(el.id || !device?.deviceBarcodeId) && (
                  <div>
                    <Radio.Group
                      options={options}
                      value={el.lifeTime}
                      optionType='button'
                      buttonStyle='solid'
                      disabled
                    />
                  </div>
                )}
              </div>
            );
          })}
      </>
    );
  };

  const getAssemblyError = () => {
    if (!device?._id) return 'DEVICE_NOT_FOUND';
    if (!device.customer?._id) return 'CUSTOMER';
    if (!device.company?._id) return 'COMPANY';
    if (!device.filters?.length) return 'FILTERS';
    if (device.deviceBarcode?.barcode) {
      for (const filter of device.filters) {
        if (filter.required && !filter.id) return 'FILTER_BARCODE';
      }
    }
    for (const filter of device.filters) {
      if (filter.required && !filter.lifeTime) return 'FILTER_LIFETIME';
    }
    return '';
  };

  const assemblyError = getAssemblyError();
  const assemblyAvailable = !assemblyError;

  const renderError = () => {
    if (assemblyAvailable) return;
    if (assemblyError === 'DEVICE_NOT_FOUND') return;
    if (assemblyError === 'CUSTOMER')
      return <div style={{ color: 'red' }}>{translations[appLanguage]['g534']}</div>;
    if (assemblyError === 'COMPANY')
      return <div style={{ color: 'red' }}>{translations[appLanguage]['g535']}</div>;
    if (assemblyError === 'FILTERS')
      return <div style={{ color: 'red' }}>{translations[appLanguage]['g536']}</div>;
    if (assemblyError === 'FILTER_BARCODE')
      return <div style={{ color: 'red' }}>{translations[appLanguage]['g537']}</div>;
    if (assemblyError === 'FILTER_LIFETIME')
      return <div style={{ color: 'red' }}>{translations[appLanguage]['g538']}</div>;
  };

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{translations[appLanguage]['g530']}</h3>
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <div style={{ width: '340px' }}>
        <div>{translations[appLanguage]['g532']}</div>
        <div style={{ display: 'flex' }}>
          <AntdInput value={id} onChange={({ target }) => setId(target.value)} />
          <Button
            style={{ marginLeft: '10px' }}
            onClick={async () => {
              const result = await searchDevices({
                filter: {
                  fields: [],
                  page: {
                    number: 1,
                    size: 500,
                  },
                  searchText: id,
                  searchTextFields: ['ID', 'deviceBarcode.barcode'],
                  sort: '-createdAt',
                },
              });
              console.log('result', result);
              if (result?.data?.length) getDevice(result?.data?.[0]?._id);
            }}
          >
            {translations[appLanguage]['g531']}
          </Button>
        </div>

        {device?._id && renderDevice()}
        {renderError()}
        {device?._id && (
          <div style={{ marginTop: '10px' }}>
            <Button
              disabled={!assemblyAvailable}
              onClick={() => {
                assemblyDevice(device?._id, () =>
                  history.push(
                    user.role === userRole.ADMIN ? '/admin/devices' : '/companyOwner/devices'
                  )
                );
              }}
            >
              {translations[appLanguage]['g533']}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
