import {
  SIGN_IN_USER_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_UP_USER_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_OUT_USER_SUCCESS,
  SET_USER,
  RESET_USER,
} from 'context/auth/keys';

export const initialState = {
  isSignInSuccess: false,
  signInError: '',
  isSignUpSuccess: false,
  signUpError: '',
  user: {},
  token: '',
  authenticated: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGN_IN_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
        isSignInSuccess: true,
        signInError: '',
        authenticated: true,
      };
    case SIGN_IN_ERROR:
      return { ...state, token: null, signInError: payload, authenticated: false };
    case SIGN_UP_USER_SUCCESS:
      return {
        ...state,
        user: payload,
        isLoading: false,
        isSignUpSuccess: true,
        authenticated: true,
      };
    case SIGN_UP_ERROR:
      return { ...state, token: null, signUpError: payload, authenticated: false };
    case SIGN_OUT_USER_SUCCESS:
      return { ...state, token: null, authenticated: false };
    case SET_USER:
      return { ...state, user: payload, authenticated: true };
    case RESET_USER:
      return { ...state, user: {}, authenticated: false };
    default:
      return state;
  }
};

export default reducer;
