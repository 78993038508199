import { apiURL, axiosClient } from 'service';
import { FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRIES_ERROR } from 'context/country/keys';

export const fetchCountries = (dispatch) => async (countryFilter) => {
  const response = (
    await axiosClient.post(apiURL.countries, countryFilter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_COUNTRIES_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_COUNTRIES_ERROR, payload: response.error });
  }
};
