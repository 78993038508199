const userRole = {
  WORKER: 'worker',
  DEVELOPER: 'developer',
  ADMIN: 'admin',
  COMPANY_OWNER: 'companyOwner',
  COMPANY_USER: 'companyUser',
  COMPANY_ACCOUNTANT: 'companyAccountant',
  MODERATOR: 'moderator',
};

export default userRole;
