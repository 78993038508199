import React, { useEffect, useContext } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import detectBrowserLanguage from 'detect-browser-language';
import { ToastContainer } from 'react-toastify';

// worker-pages
import LandingPage from 'ui/pages/common-pages/landing-page';
import SignInPage from 'ui/pages/common-pages/authentication/signin-page';
import SignUpPage from 'ui/pages/common-pages/authentication/signup-page';
import EmailVerificationPage from 'ui/pages/common-pages/authentication/email-verification-page';
import PasswordResetPage from 'ui/pages/common-pages/authentication/password-reset-page';
import AdminPages from 'ui/pages/admin-pages';
import CompanyOwnerPages from 'ui/pages/company-owner-pages';
import PublicPages from 'ui/pages/public-pages';

// contexts
import { LanguageContext, AuthContext } from 'context';
import history from './history';
// styles
import 'react-toastify/dist/ReactToastify.css';
import userRole from 'resources/constants/userRole';
import CompanyUserPages from 'ui/pages/company-user-pages';
import CompanyAccountantPages from 'ui/pages/company-accountant-pages';

function App() {
  const {
    changeAppLanguage,
    state: { languageSetted },
  } = useContext(LanguageContext);
  const {
    getUser,
    state: { isSignInSuccess, isSignUpSuccess, user },
  } = useContext(AuthContext);

  useEffect(() => {
    if (
      !window.location.pathname.startsWith('/checkFilter/') &&
      !window.location.pathname.startsWith('/checkDevice/')
    )
      getUser();
    // const token = localStorage.getItem('token');
    // if (!token) {
    //   history.push('/');
    // }
  }, []);

  useEffect(() => {
    (isSignInSuccess || isSignUpSuccess) && getUser();
  }, [isSignInSuccess, isSignUpSuccess]); //eslint-disable-line

  useEffect(() => {
    if (!languageSetted) {
      let lang = detectBrowserLanguage();
      if (lang === 'tr' || lang === 'tr-TR') lang = 'tr';
      else if (lang === 'ar') lang = 'arb';
      else lang = 'en';
      changeAppLanguage(lang);
    }
    localStorage.getItem('token') && getUser();
  }, []); //eslint-disable-line

  return (
    <>
      <ToastContainer />
      <Router history={history}>
        <Switch>
          <Route path='/' exact component={LandingPage} />
          <Route path='/signup' exact component={SignUpPage} />
          <Route path='/signin' exact component={SignInPage} />
          <Route path='/verify_email' exact component={EmailVerificationPage} />
          <Route path='/reset_password' exact component={PasswordResetPage} />
          {!user.email ? (
            <Route component={PublicPages} />
          ) : user.role === userRole.ADMIN ? (
            <Route component={AdminPages} />
          ) : user.role === userRole.COMPANY_OWNER ? (
            <Route component={CompanyOwnerPages} />
          ) : user.role === userRole.COMPANY_USER ? (
            <Route component={CompanyUserPages} />
          ) : (
            <Route component={CompanyAccountantPages} />
          )}
        </Switch>
      </Router>
    </>
  );
}

export default App;
