import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Checkbox, Select, Table } from 'antd';

// contexts
import {
  LanguageContext,
  DeviceContext,
  CompanyContext,
  AuthContext,
  CustomerContext,
} from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import Input from 'ui/reusables/input';
import { getParam } from 'helpers/page';
import Search from 'ui/components/search/search';
import history from 'history.js';
import userRole from 'resources/constants/userRole';
import { filterType } from 'resources/constants/filterType';

const { Option } = Select;
export default function AddDevice(props) {
  // const { id } = props.match.params;
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const { createDevice } = useContext(DeviceContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  const {
    state: { companies },
    fetchCompanies,
  } = useContext(CompanyContext);

  // const {
  //   state: { customers },
  //   fetchCustomers,
  // } = useContext(CustomerContext);

  const [companyId, setCompanyId] = useState();
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const [filters, setFilters] = useState(
    Object.keys(filterType).map((el) => ({
      type: filterType[el],
      required: false,
      isActive: false,
    }))
  );

  useEffect(() => {
    if (user.role === userRole.ADMIN) fetchCompanies();
  }, [user]); //eslint-disable-line

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{translations[appLanguage]['g502']}</h3>
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <div style={{ width: '300px' }}>
        <Input
          label={translations[appLanguage]['g519']}
          value={brand}
          onChange={(e) => setBrand((e.target.value || '').toUpperCase())}
        />
        <Input
          label={translations[appLanguage]['g520']}
          value={model}
          onChange={(e) => setModel((e.target.value || '').toUpperCase())}
        />

        {user.role === userRole.ADMIN && (
          <>
            {translations[appLanguage]['g204']}
            <Select
              style={{ display: 'flex', flex: 1, margin: '12px 0' }}
              value={companyId}
              onChange={(e) => setCompanyId(e)}
            >
              {companies?.map((el, index) => {
                return (
                  <Option key={index} value={el._id}>
                    {el?.name}
                  </Option>
                );
              })}
            </Select>
          </>
        )}
        <div> {translations[appLanguage]['g521'] + ':'}</div>
        {filters.map((el) => {
          return (
            <div>
              <Checkbox
                checked={el.required}
                onChange={({ target }) => {
                  filters.find((filter) => filter.type === el.type).required = target.checked;
                  setFilters([...filters]);
                }}
              >
                {translations[appLanguage]['g_filterType' + el.type]}
              </Checkbox>
            </div>
          );
        })}

        <Button
          onClick={() => {
            createDevice({ filters, brand, model, companyId }, () =>
              history.push(
                user.role === userRole.ADMIN ? '/admin/devices' : '/companyOwner/devices'
              )
            );
          }}
        >
          {translations[appLanguage]['g502']}
        </Button>
      </div>
    </div>
  );
}
