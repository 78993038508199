export const FETCH_FILTER_BARCODES_SUCCESS = 'FETCH_FILTER_BARCODES_SUCCESS';
export const FETCH_FILTER_BARCODES_ERROR = 'FETCH_FILTER_BARCODES_ERROR';
export const FETCH_FILTER_BARCODE_SUCCESS = 'FETCH_FILTER_BARCODE_SUCCESS';
export const FETCH_FILTER_BARCODE_ERROR = 'FETCH_FILTER_BARCODE_ERROR';
export const UPDATE_FILTER_BARCODE_SUCCESS = 'UPDATE_FILTER_BARCODE_SUCCESS';
export const UPDATE_FILTER_BARCODE_ERROR = 'UPDATE_FILTER_BARCODE_ERROR';
export const CREATE_FILTER_BARCODE_SUCCESS = 'CREATE_FILTER_BARCODE_SUCCESS';
export const CREATE_FILTER_BARCODE_ERROR = 'CREATE_FILTER_BARCODE_ERROR';
export const RESET = 'RESET';
export const SET_LOADING = 'SET_LOADING';
