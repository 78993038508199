import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

// helpers
import { translations } from 'resources';
import { isEmail, isEmpty, isValidPassword } from 'utils';
import { useForm } from 'hooks';

// contexts
import { AuthContext, LanguageContext } from 'context';

// components
import Input from 'ui/reusables/input';
import Button from 'ui/reusables/button';

// styles
import logo from 'resources/images/blended-logo.png';
import styles from './signin-page.module.scss';

export default function SignInPage() {
  const {
    state: { signInError },
    signInUser,
  } = useContext(AuthContext);
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);

  const [showPassword] = useState(false);

  const {
    form: { email, password, validate },
  } = useForm({
    email: {
      value: '',
      getError: (value) =>
        isEmpty(value) || !isEmail(value) ? translations[appLanguage]['g2'] : '',
    },
    password: {
      value: '',
      getError: (value) => (isValidPassword(value) ? '' : translations[appLanguage]['g7']),
    },
  });

  const handleFormSubmission = (event) => {
    event.preventDefault();
    validate() &&
      signInUser({
        email: email.value,
        password: password.value,
      });
  };

  return (
    <form onSubmit={handleFormSubmission} className={styles.signin_container}>
      <Link to='/'>
        <img className={styles.logo} src={logo} alt='logo' />
      </Link>

      <div className={styles.input_container}>
        <div className={styles.input_label}>{translations[appLanguage]['g3']}</div>
        <Input
          value={email?.value}
          onChange={(e) => email?.setValue(e.target.value)}
          placeholder={translations[appLanguage]['g3']}
        />
        <div className={styles.error_message}>
          {email?.error ? email?.error : <span>&nbsp;</span>}
        </div>
      </div>

      <div className={styles.input_container}>
        <div className={styles.input_label}>{translations[appLanguage]['g4']}</div>
        <Input
          value={password?.value}
          onChange={(e) => password?.setValue(e.target.value)}
          placeholder={translations[appLanguage]['g4']}
          type={showPassword ? 'text' : 'password'}
        />
        <div className={styles.error_message}>
          {password?.error ? (
            password?.error
          ) : signInError ? (
            translations[appLanguage]['g33']
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
      </div>
      <div className={styles.input_container}>
        <Button className={styles.button} inverted onClick={handleFormSubmission}>
          {translations[appLanguage]['g6']}
        </Button>
      </div>
    </form>
  );
}
