import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Select, Table } from 'antd';

// contexts
import { LanguageContext, ControlCardContext, CompanyContext, AuthContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import Input from 'ui/reusables/input';
import { getParam } from 'helpers/page';
import Search from 'ui/components/search/search';
import history from 'history.js';
import userRole from 'resources/constants/userRole';

const { Option } = Select;
export default function EditControlCard(props) {
  const { id } = props.match.params;
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { controlCard, loading },
    getControlCard,
    updateControlCard,
  } = useContext(ControlCardContext);

  const {
    state: { companies },
    fetchCompanies,
  } = useContext(CompanyContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  const [companyId, setCompanyId] = useState();

  useEffect(() => {
    if (id) getControlCard(id);
  }, [id]); //eslint-disable-line
  useEffect(() => {
    fetchCompanies();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (id && controlCard?._id === id) {
      setCompanyId(controlCard.companyId);
    }
  }, [controlCard]); //eslint-disable-line

  return (
    (user.role === userRole.ADMIN || user.role === userRole.COMPANY_OWNER) && (
      <div className={styles.page_container}>
        <div className={styles.title_container}>
          <h3>{translations[appLanguage]['g511']}</h3>
        </div>
        <div className={styles.divider}>&nbsp;</div>
        <div style={{ width: '300px' }}>
          <Input
            label={translations[appLanguage]['g208']}
            value={controlCard?.serialNumber}
            disabled={true}
          />
          <Input
            label={translations[appLanguage]['g208']}
            value={controlCard?.serialNumber}
            disabled={true}
          />

          <Input label={'hwVersion'} value={controlCard?.hwVersion} disabled={true} />
          <Input label={'swVersion'} value={controlCard?.swVersion} disabled={true} />
          <Input
            label={translations[appLanguage]['g510']}
            value={new Date(controlCard?.lastSeen)?.toLocaleString?.()}
            disabled={true}
          />

          {user.role === userRole.ADMIN && (
            <>
              <>{translations[appLanguage]['g204']}</>
              <Select
                style={{ display: 'flex', flex: 1, margin: '12px 0' }}
                value={companyId}
                onChange={(e) => setCompanyId(e)}
              >
                {companies?.map((el, index) => {
                  return (
                    <Option key={index} value={el._id}>
                      {el?.name}
                    </Option>
                  );
                })}
              </Select>
            </>
          )}
          {controlCard?.device?.ID ? (
            <>
              {user.role === userRole.ADMIN ? (
                <a href={'/admin/editDevice/' + controlCard?.device?._id}>
                  {translations[appLanguage]['g512'] + ' : ' + controlCard?.device?.ID}
                </a>
              ) : (
                <a href={'/companyOwner/editDevice/' + controlCard?.device?._id}>
                  {translations[appLanguage]['g512'] + ' : ' + controlCard?.device?.ID}
                </a>
              )}
            </>
          ) : (
            <div>{translations[appLanguage]['g512'] + ' : ----'}</div>
          )}

          {user.role === userRole.ADMIN ? (
            <Button
              onClick={() => {
                updateControlCard(id, { companyId }, () => history.push('/admin/controlCards'));
              }}
            >
              {translations[appLanguage]['g312']}
            </Button>
          ) : (
            <Button
              onClick={() => {
                history.push('/companyOwner/controlCards');
              }}
            >
              {translations[appLanguage]['g13']}
            </Button>
          )}
        </div>
      </div>
    )
  );
}
