import { FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRIES_ERROR } from 'context/country/keys';

export const initialState = {
  countries: [],
  errorMessage: '',
  totalCountryNumber: 0,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        countries: [...payload.data],
        totalCountryNumber: payload.totalCount,
      };
    case FETCH_COUNTRIES_ERROR:
      return { ...state, errorMessage: payload };
    default:
      return state;
  }
};

export default reducer;
