import React, { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { useForm } from 'hooks';
import { translations, constants } from 'resources';
import { isEmail, isEmpty, isValidPassword } from 'utils';
import { AuthContext, LanguageContext } from 'context';
import logo from 'resources/images/blended-logo.png';
import styles from './signup-page.module.scss';

export default function SignUpPage({ history }) {
  const { EMAIL_IN_USE } = constants.errors;
  const {
    state: { signUpError },
    signUpUser,
  } = useContext(AuthContext);
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);

  const emailInput = useRef();
  const passwordInput = useRef();
  const roleInput = useRef();

  const [showPassword] = useState(false);

  const {
    form: { email, password, confirmPassword, validate, role },
  } = useForm({
    email: {
      value: '',
      getError: (value) =>
        isEmpty(value) || !isEmail(value) ? translations[appLanguage]['g2'] : '',
    },
    role: {
      value: '',
      getError: (value) => (isEmpty(value) ? translations[appLanguage]['g2'] : ''),
    },
    password: {
      value: '',
      getError: (value) => (isValidPassword(value) ? '' : translations[appLanguage]['g7']),
    },
    confirmPassword: {
      value: '',
      getError: (value) => (isValidPassword(value) ? '' : translations[appLanguage]['g7']),
    },
  });

  const handleFormSubmission = (event) => {
    event.preventDefault();

    validate() &&
      password?.value === confirmPassword?.value &&
      signUpUser({
        email: email.value,
        password: password.value,
        role: role.value,
      });
  };

  const handleKeyPress = (event) => {
    if (event.which === 13 || event.keyCode === 13) {
      event.preventDefault();
      passwordInput.current.focus();
    }
  };

  return (
    <form onSubmit={handleFormSubmission} className={styles.signup_container}>
      <Link to='/'>
        <img className={styles.logo} src={logo} alt='logo' />
      </Link>

      <div className={styles.input_container}>
        <div className={styles.input_label}>{translations[appLanguage]['g3']}</div>
        <input
          type='email'
          ref={emailInput}
          value={email?.value}
          onChange={(e) => email?.setValue(e.target.value)}
          placeholder={translations[appLanguage]['g3']}
          onKeyPress={handleKeyPress}
        />

        <div className={styles.error_message}>
          {email?.error ? email?.error : <span>&nbsp;</span>}
        </div>
      </div>

      <div className={styles.input_container}>
        <div className={styles.input_label}>role</div>
        <input
          type='text'
          ref={roleInput}
          value={role?.value}
          onChange={(e) => role?.setValue(e.target.value)}
          placeholder={'role'}
        />

        <div className={styles.error_message}>
          {role?.error ? role?.error : <span>&nbsp;</span>}
        </div>
      </div>

      <div className={styles.input_container}>
        <div className={styles.input_label}>{translations[appLanguage]['g4']}</div>
        <input
          ref={passwordInput}
          value={password?.value}
          onChange={(e) => password?.setValue(e.target.value)}
          onBlur={(e) =>
            confirmPassword?.value &&
            password?.value !== confirmPassword?.value &&
            password?.setError(translations[appLanguage]['g26'])
          }
          placeholder={translations[appLanguage]['g4']}
          type={showPassword ? 'text' : 'password'}
          name='password'
        />
        <div className={styles.error_message}>
          {password?.error ? password?.error : <span>&nbsp;</span>}
        </div>
      </div>

      <div className={styles.input_container}>
        <div className={styles.input_label}>{translations[appLanguage]['g25']}</div>
        <input
          value={confirmPassword?.value}
          onChange={(e) => confirmPassword?.setValue(e.target.value)}
          onBlur={(e) =>
            password?.value !== confirmPassword?.value &&
            password?.setError(translations[appLanguage]['g26'])
          }
          placeholder={translations[appLanguage]['25']}
          type={showPassword ? 'text' : 'password'}
          name='passwordConfirm'
        />
        <div className={styles.error_message}>
          {confirmPassword?.error ? confirmPassword?.error : <span>&nbsp;</span>}
        </div>
      </div>
      {signUpError === EMAIL_IN_USE ? (
        <div className={styles.error_message}>{translations[appLanguage]['g32']}</div>
      ) : (
        <span>&nbsp;</span>
      )}
      <div className={styles.input_container}>
        <button onClick={handleFormSubmission}>{translations[appLanguage]['g5']}</button>
      </div>
      <Link to='/signin' className={styles.route_text_container}>
        {translations[appLanguage]['g10']}
      </Link>
    </form>
  );
}
