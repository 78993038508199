import { apiURL, axiosClient } from 'service';
import {
  FETCH_WORKERS_SUCCESS,
  FETCH_WORKERS_ERROR,
  FETCH_WORKER_SUCCESS,
  FETCH_WORKER_ERROR,
  ADD_WORKER_SUCCESS,
  ADD_WORKER_ERROR,
  RESET_WORKER,
  UPDATE_WORKER_SUCCESS,
  UPDATE_WORKER_ERROR,
  SET_LOADING,
} from 'context/worker/keys';
import history from 'history.js';

export const fetchWorkers = (dispatch) => async (workerFilter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.workers, workerFilter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_WORKERS_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_WORKERS_ERROR, payload: response.error });
  }
};

export const fetchWorkerById = (dispatch) => async (workerID, callback) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.get(`${apiURL.worker}/${workerID}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_WORKER_SUCCESS, payload: response });
    callback(response);
  } else {
    dispatch({ type: FETCH_WORKER_ERROR, payload: response.error });
  }
};

export const addWorker = (dispatch) => async (worker, notify, url) => {
  const response = (
    await axiosClient.post(apiURL.addWorker, worker, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: ADD_WORKER_SUCCESS, payload: response });
    notify();
    history.push(url);
  } else {
    dispatch({ type: ADD_WORKER_ERROR, payload: response.error });
  }
};

export const updateWorker = (dispatch) => async (worker, notify, url, workerID) => {
  const response = (
    await axiosClient.put(`${apiURL.updateWorker}/${workerID}`, worker, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response?.error?.message) {
    dispatch({ type: UPDATE_WORKER_SUCCESS, payload: response });
    notify();
    history.push(url);
  } else {
    dispatch({ type: UPDATE_WORKER_ERROR, payload: response.error.message });
  }
};

export const resetWorker = (dispatch) => () => {
  dispatch({ type: RESET_WORKER });
};
