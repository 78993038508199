import {
  HomeOutlined,
  SettingOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined,
  InsertRowBelowOutlined,
  ImportOutlined,
  InboxOutlined,
  ShopOutlined,
  TeamOutlined,
  FileTextOutlined,
  UserOutlined,
  RobotOutlined,
  UserAddOutlined,
  UnorderedListOutlined,
  BankOutlined,
  PlusCircleOutlined,
  BoxPlotOutlined,
  InsertRowLeftOutlined,
  ToolOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import { translations } from 'resources';

export const adminMenu = (appLanguage) => [
  {
    dropdown: false,
    url: '/home',
    icon: HomeOutlined,
    name: translations[appLanguage]['g546'],
  },
  {
    dropdown: true,
    icon: RobotOutlined,
    name: translations[appLanguage]['g412'],
    menuItems: [
      {
        url: '/admin/devices',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g501'],
      },
      {
        url: '/admin/addDevice',
        icon: PlusCircleOutlined,
        name: translations[appLanguage]['g502'],
      },
      {
        url: '/admin/assemblyDevice',
        icon: ToolOutlined,
        name: translations[appLanguage]['g530'],
      },
    ],
  },
  {
    dropdown: true,
    icon: BoxPlotOutlined,
    name: translations[appLanguage]['g509'],
    menuItems: [
      {
        url: '/admin/controlCards',
        icon: BoxPlotOutlined,
        name: translations[appLanguage]['g509'],
      },
    ],
  },
  {
    dropdown: true,
    icon: InsertRowLeftOutlined,
    name: translations[appLanguage]['g528'],
    menuItems: [
      {
        url: '/admin/deviceBarcodes',
        icon: BoxPlotOutlined,
        name: translations[appLanguage]['g528'],
      },
      {
        url: '/admin/addDeviceBarcode',
        icon: PlusCircleOutlined,
        name: translations[appLanguage]['g526'],
      },
    ],
  },
  {
    dropdown: true,
    icon: InsertRowLeftOutlined,
    name: translations[appLanguage]['g513'],
    menuItems: [
      {
        url: '/admin/filterBarcodes',
        icon: BoxPlotOutlined,
        name: translations[appLanguage]['g513'],
      },
      {
        url: '/admin/addFilterBarcode',
        icon: PlusCircleOutlined,
        name: translations[appLanguage]['g514'],
      },
    ],
  },
  {
    dropdown: true,
    icon: TeamOutlined,
    name: translations[appLanguage]['g493'],
    menuItems: [
      {
        url: '/admin/customers',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g494'],
      },
      {
        url: '/admin/addCustomer',
        icon: UserAddOutlined,
        name: translations[appLanguage]['g495'],
      },
    ],
  },
  {
    dropdown: true,
    icon: BankOutlined,
    name: translations[appLanguage]['g146'],
    menuItems: [
      {
        url: '/admin/companies',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g497'],
      },
      {
        url: '/admin/addCompany',
        icon: UserAddOutlined,
        name: translations[appLanguage]['g152'],
      },
    ],
  },
  {
    dropdown: true,
    icon: DollarOutlined,
    name: translations[appLanguage]['g549'],
    menuItems: [
      {
        url: '/admin/payments',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g550'],
      },
      {
        url: '/admin/addPayment',
        icon: PlusCircleOutlined,
        name: translations[appLanguage]['g551'],
      },
    ],
  },
  {
    dropdown: true,
    icon: UserOutlined,
    name: translations[appLanguage]['g503'],
    menuItems: [
      {
        url: '/admin/users',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g504'],
      },
      {
        url: '/admin/addUser',
        icon: UserAddOutlined,
        name: translations[appLanguage]['g505'],
      },
    ],
  },
  {
    dropdown: false,
    url: '/admin/settings',
    icon: SettingOutlined,
    name: translations[appLanguage]['g107'],
  },
];

export const companyUserMenu = (appLanguage) => [
  {
    dropdown: false,
    url: '/home',
    icon: HomeOutlined,
    name: translations[appLanguage]['g546'],
  },

  {
    dropdown: true,
    icon: RobotOutlined,
    name: translations[appLanguage]['g412'],
    menuItems: [
      {
        url: '/companyUser' + '/devices',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g501'],
      },
      {
        url: '/companyUser' + '/addDevice',
        icon: PlusCircleOutlined,
        name: translations[appLanguage]['g502'],
      },
      {
        url: '/companyUser' + '/assemblyDevice',
        icon: ToolOutlined,
        name: translations[appLanguage]['g530'],
      },
    ],
  },
  {
    dropdown: true,
    icon: BoxPlotOutlined,
    name: translations[appLanguage]['g509'],
    menuItems: [
      {
        url: '/companyUser' + '/controlCards',
        icon: BoxPlotOutlined,
        name: translations[appLanguage]['g509'],
      },
    ],
  },
  {
    dropdown: true,
    icon: InsertRowLeftOutlined,
    name: translations[appLanguage]['g528'],
    menuItems: [
      {
        url: '/companyUser' + '/deviceBarcodes',
        icon: BoxPlotOutlined,
        name: translations[appLanguage]['g528'],
      },
    ],
  },
  {
    dropdown: true,
    icon: InsertRowLeftOutlined,
    name: translations[appLanguage]['g513'],
    menuItems: [
      {
        url: '/companyUser' + '/filterBarcodes',
        icon: BoxPlotOutlined,
        name: translations[appLanguage]['g513'],
      },
    ],
  },
  {
    dropdown: true,
    icon: TeamOutlined,
    name: translations[appLanguage]['g493'],
    menuItems: [
      {
        url: '/companyUser' + '/customers',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g494'],
      },
      {
        url: '/companyUser' + '/addCustomer',
        icon: UserAddOutlined,
        name: translations[appLanguage]['g495'],
      },
    ],
  },
  {
    dropdown: false,
    url: '/companyUser' + '/settings',
    icon: SettingOutlined,
    name: translations[appLanguage]['g107'],
  },
];

export const companyOwnerMenu = (appLanguage) => [
  {
    dropdown: false,
    url: '/home',
    icon: HomeOutlined,
    name: translations[appLanguage]['g546'],
  },

  {
    dropdown: true,
    icon: RobotOutlined,
    name: translations[appLanguage]['g412'],
    menuItems: [
      {
        url: '/companyOwner' + '/devices',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g501'],
      },
      {
        url: '/companyOwner' + '/addDevice',
        icon: PlusCircleOutlined,
        name: translations[appLanguage]['g502'],
      },
      {
        url: '/companyOwner' + '/assemblyDevice',
        icon: ToolOutlined,
        name: translations[appLanguage]['g530'],
      },
    ],
  },
  {
    dropdown: true,
    icon: BoxPlotOutlined,
    name: translations[appLanguage]['g509'],
    menuItems: [
      {
        url: '/companyOwner' + '/controlCards',
        icon: BoxPlotOutlined,
        name: translations[appLanguage]['g509'],
      },
    ],
  },
  {
    dropdown: true,
    icon: InsertRowLeftOutlined,
    name: translations[appLanguage]['g528'],
    menuItems: [
      {
        url: '/companyOwner' + '/deviceBarcodes',
        icon: BoxPlotOutlined,
        name: translations[appLanguage]['g528'],
      },
    ],
  },
  {
    dropdown: true,
    icon: InsertRowLeftOutlined,
    name: translations[appLanguage]['g513'],
    menuItems: [
      {
        url: '/companyOwner' + '/filterBarcodes',
        icon: BoxPlotOutlined,
        name: translations[appLanguage]['g513'],
      },
    ],
  },
  {
    dropdown: true,
    icon: TeamOutlined,
    name: translations[appLanguage]['g493'],
    menuItems: [
      {
        url: '/companyOwner' + '/customers',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g494'],
      },
      {
        url: '/companyOwner' + '/addCustomer',
        icon: UserAddOutlined,
        name: translations[appLanguage]['g495'],
      },
    ],
  },
  {
    dropdown: true,
    icon: DollarOutlined,
    name: translations[appLanguage]['g549'],
    menuItems: [
      {
        url: '/companyOwner' + '/payments',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g550'],
      },
      {
        url: '/companyOwner' + '/addPayment',
        icon: PlusCircleOutlined,
        name: translations[appLanguage]['g551'],
      },
    ],
  },
  {
    dropdown: true,
    icon: UserOutlined,
    name: translations[appLanguage]['g503'],
    menuItems: [
      {
        url: '/companyOwner' + '/users',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g504'],
      },
      {
        url: '/companyOwner' + '/addUser',
        icon: UserAddOutlined,
        name: translations[appLanguage]['g505'],
      },
    ],
  },
  {
    dropdown: false,
    url: '/companyOwner' + '/settings',
    icon: SettingOutlined,
    name: translations[appLanguage]['g107'],
  },
];
export const companyAccountantMenu = (appLanguage) => [
  {
    dropdown: false,
    url: '/home',
    icon: HomeOutlined,
    name: translations[appLanguage]['g546'],
  },

  {
    dropdown: true,
    icon: RobotOutlined,
    name: translations[appLanguage]['g412'],
    menuItems: [
      {
        url: '/companyAccountant' + '/devices',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g501'],
      },
    ],
  },
  {
    dropdown: true,
    icon: BoxPlotOutlined,
    name: translations[appLanguage]['g509'],
    menuItems: [
      {
        url: '/companyAccountant' + '/controlCards',
        icon: BoxPlotOutlined,
        name: translations[appLanguage]['g509'],
      },
    ],
  },
  {
    dropdown: true,
    icon: InsertRowLeftOutlined,
    name: translations[appLanguage]['g528'],
    menuItems: [
      {
        url: '/companyAccountant' + '/deviceBarcodes',
        icon: BoxPlotOutlined,
        name: translations[appLanguage]['g528'],
      },
    ],
  },
  {
    dropdown: true,
    icon: InsertRowLeftOutlined,
    name: translations[appLanguage]['g513'],
    menuItems: [
      {
        url: '/companyAccountant' + '/filterBarcodes',
        icon: BoxPlotOutlined,
        name: translations[appLanguage]['g513'],
      },
    ],
  },
  {
    dropdown: true,
    icon: TeamOutlined,
    name: translations[appLanguage]['g493'],
    menuItems: [
      {
        url: '/companyAccountant' + '/customers',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g494'],
      },
      {
        url: '/companyAccountant' + '/addCustomer',
        icon: UserAddOutlined,
        name: translations[appLanguage]['g495'],
      },
    ],
  },
  {
    dropdown: true,
    icon: DollarOutlined,
    name: translations[appLanguage]['g549'],
    menuItems: [
      {
        url: '/companyAccountant' + '/payments',
        icon: UnorderedListOutlined,
        name: translations[appLanguage]['g550'],
      },
      {
        url: '/companyAccountant' + '/addPayment',
        icon: PlusCircleOutlined,
        name: translations[appLanguage]['g551'],
      },
    ],
  },
  {
    dropdown: false,
    url: '/companyAccountant' + '/settings',
    icon: SettingOutlined,
    name: translations[appLanguage]['g107'],
  },
];
