import React, { useContext } from 'react';
import { Route } from 'react-router-dom';

// admin-pages
// import Home from 'ui/pages/admin-pages/home';
import Devices from 'ui/pages/devices';
import Customers from 'ui/pages/customers';
import Users from 'ui/pages/users';
import AddCustomer from 'ui/pages/addCustomer';
import AddUser from 'ui/pages/addUser';
import Companies from 'ui/pages/companies';
import AddCompany from 'ui/pages/editCompany';
import PasswordChange from 'ui/pages/settings/password-change';
import SettingsPage from 'ui/pages/settings/settings-page';
import ControlCards from 'ui/pages/controlCards';
import EditControlCard from 'ui/pages/editControlCard/';
import CheckFilterBarcode from 'ui/pages/checkFilterBarcode';
import CheckDeviceBarcode from 'ui/pages/checkDeviceBarcode';

// components
import Sidebar from 'ui/components/sidebar';
import Topbar from 'ui/components/topbar';

// contexts
import { LanguageContext, AuthContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import {
  HomeOutlined,
  SettingOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined,
  InsertRowBelowOutlined,
  ImportOutlined,
  InboxOutlined,
  ShopOutlined,
  TeamOutlined,
  FileTextOutlined,
  UserOutlined,
  RobotOutlined,
  UserAddOutlined,
  UnorderedListOutlined,
  BankOutlined,
  PlusCircleOutlined,
  BoxPlotOutlined,
} from '@ant-design/icons';
import styles from './index.module.scss';

const urlPrefix = '/companyOwner';

const PublicPages = () => {
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  return (
    <>
      <div className={styles.main_content_container}>
        <Route path={'/checkFilter/:id'} exact component={CheckFilterBarcode} />
        <Route path={'/checkDevice/:id'} exact component={CheckDeviceBarcode} />
      </div>
    </>
  );
};

export default PublicPages;
