import { apiURL, axiosClient } from 'service';
import {
  CREATE_DEVICE_BARCODE_ERROR,
  CREATE_DEVICE_BARCODE_SUCCESS,
  FETCH_DEVICE_BARCODES_ERROR,
  FETCH_DEVICE_BARCODES_SUCCESS,
  FETCH_DEVICE_BARCODE_ERROR,
  FETCH_DEVICE_BARCODE_SUCCESS,
  SET_LOADING,
  UPDATE_DEVICE_BARCODE_ERROR,
  UPDATE_DEVICE_BARCODE_SUCCESS,
  RESET,
} from 'context/deviceBarcode/keys';
import history from 'history.js';

export const fetchDeviceBarcodes = (dispatch) => async (deviceFilter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.deviceBarcodes, deviceFilter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_DEVICE_BARCODES_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_DEVICE_BARCODES_ERROR, payload: response.error });
  }
};

export const searchDeviceBarcodes = (dispatch) => async (deviceFilter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.deviceBarcodes, deviceFilter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  return response;
};

export const getDeviceBarcode = (dispatch) => async (deviceId) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.get(`${apiURL.deviceBarcode}/${deviceId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_DEVICE_BARCODE_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_DEVICE_BARCODE_ERROR, payload: response.error });
  }
};

export const updateDeviceBarcode = (dispatch) => async (id, filterBarcode, callBack) => {
  const response = (
    await axiosClient.put(`${apiURL.deviceBarcode}/${id}`, filterBarcode, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: UPDATE_DEVICE_BARCODE_SUCCESS, payload: response });
    if (callBack) callBack();
    // history.push('/admin/companies');
  } else {
    dispatch({ type: UPDATE_DEVICE_BARCODE_ERROR, payload: response.error });
  }
};

export const createDeviceBarcode = (dispatch) => async (filterBarcode, callBack) => {
  const response = (
    await axiosClient.post(apiURL.deviceBarcode, filterBarcode, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: CREATE_DEVICE_BARCODE_SUCCESS, payload: response });
    if (callBack) callBack();
    // history.push('/admin/companies');
  } else {
    dispatch({ type: CREATE_DEVICE_BARCODE_ERROR, payload: response.error });
  }
};
export const resetDeviceBarcode = (dispatch) => async () => {
  dispatch({ type: RESET });
};
