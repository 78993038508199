import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Select, Table } from 'antd';

// contexts
import { LanguageContext, CustomerContext, CompanyContext, AuthContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import Input from 'ui/reusables/input';
import { getParam } from 'helpers/page';
import Search from 'ui/components/search/search';
import history from 'history.js';
import userRole from 'resources/constants/userRole';

const { Option } = Select;
export default function EditCustomer(props) {
  const { id } = props.match.params;
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { customer, loading },
    createCustomer,
    getCustomer,
    updateCustomer,
  } = useContext(CustomerContext);

  const {
    state: { companies },
    fetchCompanies,
  } = useContext(CompanyContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [companyId, setCompanyId] = useState();
  const [note, setNote] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');

  useEffect(() => {
    if (id) getCustomer(id);
  }, [id]); //eslint-disable-line
  useEffect(() => {
    fetchCompanies();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (id && customer?._id === id) {
      setFirstName(customer.firstName);
      setLastName(customer.lastName);
      setEmail(customer.email);
      setPhone(customer.phone);
      setCompanyId(customer.companyId);
      setNote(customer.note);
      setAddress(customer.address);
      setCity(customer.city);
      setDistrict(customer.district || "");
    }
  }, [customer]); //eslint-disable-line

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{id ? translations[appLanguage]['g496'] : translations[appLanguage]['g495']}</h3>
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <div style={{ width: '300px' }}>
        <Input
          label={translations[appLanguage]['g14']}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Input
          label={translations[appLanguage]['g15']}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Input label='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
        <Input
          label={translations[appLanguage]['g1']}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <Input
          label={translations[appLanguage]['g304']}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <Input
          label={translations[appLanguage]['g155']}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <Input
          label={translations[appLanguage]['g555']}
          value={district}
          onChange={(e) => setDistrict((e.target.value || '').toLocaleUpperCase())}
        />
        <Input
          label={translations[appLanguage]['g177']}
          value={city}
          onChange={(e) => setCity((e.target.value || '').toLocaleUpperCase())}
        />

        {user.role === userRole.ADMIN && (
          <>
            <>{translations[appLanguage]['g204']}</>
            <Select
              style={{ display: 'flex', flex: 1, margin: '12px 0' }}
              value={companyId}
              onChange={(e) => setCompanyId(e)}
            >
              {companies?.map((el, index) => {
                return (
                  <Option key={index} value={el._id}>
                    {el?.name}
                  </Option>
                );
              })}
            </Select>
          </>
        )}

        <Button
          onClick={() => {
            const customer = { firstName, lastName, email, phone, companyId, note, address,district, city };
            id
              ? updateCustomer(id, customer, () => history.push('/admin/customers'))
              : createCustomer(customer, () => history.push('/admin/customers'));
          }}
        >
          {id ? translations[appLanguage]['g496'] : translations[appLanguage]['g495']}
        </Button>
      </div>
      {/* <Search onChange={setFilter} />
      <div className={styles.table_container}>
        <Table
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setVisible(true);
                setCurrentProduct(record);
              },
            };
          }}
          size={'middle'}
          loading={loading && !data?.length}
          bordered
          columns={columns}
          dataSource={data}
          scroll={{ x: 600, y: calcViewWidth > 995 ? calcViewHeight - 310 : calcViewHeight - 260 }}
          expandable
          pagination={{ onChange: onPageChange, current: pageIndex, pageSize }}
        />
      </div> */}
    </div>
  );
}
