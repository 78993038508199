import {
  FETCH_PAYMENTS_SUCCESS,
  FETCH_PAYMENTS_ERROR,
  FETCH_PAYMENT_ERROR,
  FETCH_PAYMENT_SUCCESS,
  SET_LOADING,
} from 'context/payment/keys';

export const initialState = {
  payments: [],
  errorMessage: '',
  payment: {},
  totalPaymentNumber: 0,
  totalCount: 0,
  loading: true,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        payments: [...payload.data],
        totalPaymentNumber: payload.totalCount,
        loading: false,
      };
    case FETCH_PAYMENTS_ERROR:
      return { ...state, errorMessage: payload };
    case FETCH_PAYMENT_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        payment: payload,
        loading: false,
      };
    case FETCH_PAYMENT_ERROR:
      return { ...state, errorMessage: payload };
    default:
      return state;
  }
};

export default reducer;
