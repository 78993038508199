import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Table, Modal } from 'antd';

// contexts
import { LanguageContext, DeviceBarcodeContext, AuthContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import { getParam } from 'helpers/page';
import Search from 'ui/components/search/search';
import Input from 'ui/reusables/input';

import history from 'history.js';
import userRole from 'resources/constants/userRole';
import { filterStatus } from 'resources/constants/filterType';

export default function DeviceBarcodes(props) {
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { user },
  } = useContext(AuthContext);
  const {
    state: { deviceBarcodes, loading, totalDeviceBarcodeNumber },
    fetchDeviceBarcodes,
    resetDeviceBarcode,
  } = useContext(DeviceBarcodeContext);

  const [calcViewHeight, setCalcViewHeight] = useState(window.innerHeight);
  const [calcViewWidth, setCalcViewWidth] = useState(window.innerWidth);
  const [currentProduct, setCurrentProduct] = useState({});
  const [visible, setVisible] = useState(false);
  const [filter, setFilter] = useState({});
  const [pageIndex, setPageIndex] = useState(getParam(props, 'pageIndex', 1));
  const [pageSize, setPageSize] = useState(getParam(props, 'pageSize', 20));

  const onPageChange = (newPageIndex, newPageSize) => {
    setPageIndex(newPageIndex);
    setPageSize(newPageSize);
  };

  const getFilteredItems = (pageIndex) => {
    filter.page = { number: parseInt(pageIndex), size: parseInt(pageSize) };

    filter.sort = '-createdAt';
    fetchDeviceBarcodes({ filter });
    if (
      pageIndex !== getParam(props, 'pageIndex', 1) ||
      pageSize !== getParam(props, 'pageSize', 20)
    )
      props.history.push(`${props.location.pathname}?pageIndex=${pageIndex}&pageSize=${pageSize}`);
  };

  useEffect(() => {
    onPageChange(1, pageSize);
    getFilteredItems(1);
  }, [filter]); //eslint-disable-line

  useEffect(() => {
    getFilteredItems(pageIndex);
  }, [pageIndex, pageSize]); //eslint-disable-line

  const handleModalClose = useCallback(() => {
    setVisible(false);
  }, []);

  const onResize = useCallback(() => {
    const calcViewHeight = window.innerHeight;
    const calcViewWidth = window.innerWidth;
    setCalcViewHeight(calcViewHeight);
    setCalcViewWidth(calcViewWidth);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => onResize());
  }, [onResize]);

  const getUrlPrefix = useCallback(() => {
    if (user.role === userRole.ADMIN) return '/admin';
    else if (user.role === userRole.COMPANY_OWNER) return '/companyOwner';
    else if (user.role === userRole.COMPANY_USER) return '/companyUser';
    else if (user.role === userRole.COMPANY_ACCOUNTANT) return '/companyAccountant';
    else if (user.role === userRole.WORKER) return '/worker';
  }, [user.role]);

  const data = new Array(totalDeviceBarcodeNumber);
  const newProducts = [];
  for (let i = 0; i < deviceBarcodes?.length; i++) {
    let item = deviceBarcodes[i];
    newProducts.push({
      ...item,
      ind: i,
      key: i,
      barcode: item.barcode,
      companyName: item.company?.name || '--',
      deviceId: item.device?.ID || '--',
      status: translations[appLanguage]['g_filterStatus' + item.status],
    });
  }
  data.splice(pageSize * (pageIndex - 1), pageSize, ...newProducts);

  const columns = [
    {
      title: '#',
      dataIndex: 'no',
      key: 'no',
      width: 50,
      fixed: 'left',
      render: (e, record) => <div>{record.ind + 1}</div>,
    },
    {
      title: translations[appLanguage]['g328'],
      width: 60,
      dataIndex: 'barcode',
      key: 'barcode',
    },
    {
      title: translations[appLanguage]['g204'],
      width: 60,
      dataIndex: 'companyName',
      key: 'companyName',
    },
    {
      title: translations[appLanguage]['g458'],
      width: 40,
      dataIndex: 'deviceId',
      key: 'deviceId',
      render: (e, record) => (
        <a href={getUrlPrefix() + '/editDevice/' + record.device?._id}>{record.deviceId}</a>
      ),
    },
    {
      title: translations[appLanguage]['g207'],
      width: 40,
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: ' ',
      dataIndex: 'edit',
      key: 'edit',
      width: 50,
      render: (e, record) => (
        <div style={{ display: 'flex' }}>
          <Button
            onClick={() => {
              user.role === userRole.ADMIN
                ? history.push('/admin/editDeviceBarcode/' + record._id)
                : history.push('/companyOwner/editDeviceBarcode/' + record._id);
            }}
          >
            {translations[appLanguage][user.role === userRole.ADMIN ? 'g309' : 'g543']}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{translations[appLanguage]['g528']}</h3>
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <Search
        onChange={setFilter}
        companyEnabled={user.role === userRole.ADMIN}
        dateField='createdAt'
        statusOptions={Object.keys(filterStatus).map((el) => ({
          text: translations[appLanguage]['g_filterStatus' + filterStatus[el]],
          value: filterStatus[el],
        }))}
      />
      <div className={styles.table_container}>
        <Table
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setVisible(true);
                setCurrentProduct(record);
              },
            };
          }}
          size={'middle'}
          loading={loading && !data?.length}
          bordered
          columns={columns}
          dataSource={data}
          scroll={{ x: 600, y: calcViewWidth > 995 ? calcViewHeight - 310 : calcViewHeight - 260 }}
          expandable
          pagination={{ onChange: onPageChange, current: pageIndex, pageSize }}
        />
      </div>
    </div>
  );
}
