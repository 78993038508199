import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Progress, Select, Table } from 'antd';
import QRCode from 'react-qr-code';

// contexts
import { LanguageContext, FilterBarcodeContext, CompanyContext, AuthContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import Input from 'ui/reusables/input';
import { getParam } from 'helpers/page';
import history from 'history.js';
import userRole from 'resources/constants/userRole';
import { filterType } from 'resources/constants/filterType';
import { axiosClient } from 'service';
import { StopOutlined } from '@ant-design/icons';

const { Option } = Select;
let intervalId;
export default function CheckDeviceBarcode(props) {
  const { id } = props.match.params;
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);

  const [device, setDevice] = useState({});
  const [wrongBarcode, setWrongBarcode] = useState(false);
  const [remainFilterTimes, setRemainFilterTimes] = useState(0);

  const getDeviceData = async () => {
    const response = await axiosClient.get('/wapi/v1/globalRoutes/checkDeviceBarcode/' + id);
    if (response?.data?.error) {
      if (response?.data?.error?.code === 1010) setWrongBarcode(response.data);
    } else {
      console.log('errorsss', response.data);
      setDevice(response.data);
    }
  };

  useEffect(() => {
    if (id) {
      getDeviceData();
    }
  }, [id]); //eslint-disable-line

  const renderFilter = (filter, index) => {
    return (
      <div style={{ textAlign: '-webkit-center' }}>
        <div style={{ fontWeight: 'bold', fontSize: 'Large', margin: '5px' }}>
          {translations[appLanguage]['g_filterType' + filter?.type]}
        </div>
        {filter?.startDate && filter?.finishDate && (
          <Progress
            type='circle'
            percent={
              Date.now() > filter?.finishDate
                ? 0
                : parseInt(
                    ((filter?.finishDate - Date.now()) / (filter?.finishDate - filter?.startDate)) *
                      100
                  )
            }
            status={Date.now() > filter?.finishDate ? 'exception' : ''}
          />
        )}
        {Date.now() < filter?.finishDate && <div>Filtrenin bitmesine :</div>}
        {filter?.finishDate && (
          <div>
            {Date.now() > filter?.finishDate
              ? 'Filtreniz Kirli'
              : device.companyName === 'WATERTEAM'
              ? ''
              : parseInt(remainFilterTimes[index] / DAY) +
                'Gün ,' +
                parseInt((remainFilterTimes[index] % DAY) / HOUR) +
                'Saat ,' +
                parseInt((remainFilterTimes[index] % HOUR) / MINUTE) +
                'Dakika ,' +
                parseInt((remainFilterTimes[index] % MINUTE) / SECOND) +
                ' Saniye kaldı'}
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    clearInterval(intervalId);
    intervalId = setInterval(() => {
      setRemainFilterTimes(device?.filters.map((filter) => filter?.finishDate - Date.now()));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [device?.filters?.[0]?.finishDate]); //eslint-disable-line

  const renderFilters = () => {
    if (device?.filters)
      return device.filters.map((filter, i) => filter.required && renderFilter(filter, i));
  };

  const SECOND = 1000;
  const MINUTE = 60 * SECOND;
  const HOUR = 60 * MINUTE;
  const DAY = 24 * HOUR;

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{translations[appLanguage]['g539']}</h3>
      </div>

      <div className={styles.divider}>&nbsp;</div>

      {wrongBarcode && <div>{translations[appLanguage]['g518']}</div>}
      {!wrongBarcode && (
        <div style={{ width: '300px' }}>
          {device.waterLeak && (
            <div style={{ color: 'red' }}>
              <div>
                <StopOutlined />
              </div>
              <div> {translations[appLanguage]['g548']}</div>
            </div>
          )}
          <Input
            label={translations[appLanguage]['g328']}
            value={device?.barcode || '--'}
            disabled
          />
          <Input
            label={translations[appLanguage]['g207']}
            value={
              device?.status ? translations[appLanguage]['g_deviceStatus' + device?.status] : '--'
            }
            disabled
          />
          <Input label={translations[appLanguage]['g519']} value={device?.brand} disabled />
          <Input label={translations[appLanguage]['g520']} value={device?.model} disabled />
          <Input label={translations[appLanguage]['g204']} value={device?.companyName} disabled />
          <div style={{ display: 'flex' }}>
            <Input
              label={translations[appLanguage]['g517']}
              value={device?.companyPhone}
              disabled
            />
            <a className={styles.call_now_Button} href={'tel:' + device?.companyPhone}>
              {translations[appLanguage]['g542']}
            </a>
          </div>{' '}
          <Input label={translations[appLanguage]['g540']} value={device?.customerName} disabled />
          {/* <Input label={translations[appLanguage]['g541']} value={device?.assemblerName} disabled /> */}
          <div>{translations[appLanguage]['g521']}</div>
          {renderFilters()}
        </div>
      )}
    </div>
  );
}
