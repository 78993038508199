import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Checkbox, Radio, Select, Table } from 'antd';

// contexts
import {
  LanguageContext,
  DeviceContext,
  CompanyContext,
  AuthContext,
  CustomerContext,
  ControlCardContext,
  FilterBarcodeContext,
  DeviceBarcodeContext,
} from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import Input from 'ui/reusables/input';
import { getParam } from 'helpers/page';
import Search from 'ui/components/search/search';
import history from 'history.js';
import userRole from 'resources/constants/userRole';
import { filterStatus, filterType } from 'resources/constants/filterType';
import QRCode from 'react-qr-code';

const { Option } = Select;
export default function EditDevice(props) {
  const { id } = props.match.params;
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { device },
    getDevice,
    resetDevice,
    editDevice,
    editAssembledDevice,
    createBarcodeExcel,
  } = useContext(DeviceContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  const {
    state: { companies },
    fetchCompanies,
  } = useContext(CompanyContext);
  const {
    state: { customers },
    fetchCustomers,
  } = useContext(CustomerContext);
  const {
    state: { controlCards },
    fetchControlCards,
    searchControlCards,
  } = useContext(ControlCardContext);
  const {
    state: { filterBarcodes },
    fetchFilterBarcodes,
    searchFilterBarcodes,
  } = useContext(FilterBarcodeContext);
  const {
    state: { deviceBarcodes },
    fetchDeviceBarcodes,
    searchDeviceBarcodes,
  } = useContext(DeviceBarcodeContext);

  // const {
  //   state: { customers },
  //   fetchCustomers,
  // } = useContext(CustomerContext);

  const [companyId, setCompanyId] = useState();
  const [customerId, setCustomerId] = useState('');
  const [controlCardId, setControlCardId] = useState('');
  const [deviceBarcodeId, setDeviceBarcodeId] = useState('');
  const [filters, setFilters] = useState([]);
  const [matchedFilters, setMatchedFilters] = useState([]);
  const [matchedDeviceBarcodes, setMatchedDeviceBarcodes] = useState([]);
  const [matchedControlCard, setMatchedControlCard] = useState();
  const [isActive, setIsActive] = useState(false);
  const [barcodeExcel, setBarcodeExcel] = useState('');

  useEffect(() => {
    if (device) {
      device.filters?.forEach((el) => (el.lifeTime = el.lifeTime || 1000 * 60 * 60 * 24 * 10));
      setFilters(device.filters);
      setControlCardId(device.controlCardId);
      setCustomerId(device.customerId);
      setCompanyId(device.companyId);
      setDeviceBarcodeId(device.deviceBarcodeId);
      setIsActive(device.isActive);
    }
  }, [device]); //eslint-disable-line
  useEffect(() => {
    getDevice(id);
  }, [id]); //eslint-disable-line
  useEffect(() => {
    resetDevice(id);
    if (user.role !== userRole.ADMIN) {
      fetchCustomers({ filter: { page: { number: 1, size: 500 }, sort: '-createdAt' } });
      fetchControlCards({
        filter: {
          fields: [{ condition: 'equal', value: [false, null], dataField: 'deviceMatched' }],
          page: {
            number: 1,
            size: 500,
          },
          searchText: '',
          sort: '-createdAt',
        },
      });
      fetchFilterBarcodes({
        filter: {
          fields: [{ condition: 'equal', value: filterStatus.NEW, dataField: 'status' }],
          page: {
            number: 1,
            size: 500,
          },
          searchText: '',
          sort: '-createdAt',
        },
      });
      fetchDeviceBarcodes({
        filter: {
          fields: [{ condition: 'equal', value: filterStatus.NEW, dataField: 'status' }],
          page: {
            number: 1,
            size: 500,
          },
          searchText: '',
          sort: '-createdAt',
        },
      });
    } else fetchCompanies();

    searchFilterBarcodes({
      filter: {
        fields: [{ condition: 'equal', value: id, dataField: 'deviceId' }],
        page: {
          number: 1,
          size: 500,
        },
        searchText: '',
        sort: '-createdAt',
      },
    }).then((response) => {
      console.log(response?.data);
      if (response?.data) setMatchedFilters(response?.data);
    });
  }, [user?.role]); //eslint-disable-line

  useEffect(() => {
    if (user.role === userRole.ADMIN) {
      fetchFilterBarcodes({
        filter: {
          fields: [
            { condition: 'equal', value: companyId, dataField: 'companyId' },
            { condition: 'equal', value: filterStatus.NEW, dataField: 'status' },
          ],
          page: {
            number: 1,
            size: 500,
          },
          searchText: '',
          sort: '-createdAt',
        },
      });
      fetchDeviceBarcodes({
        filter: {
          fields: [
            { condition: 'equal', value: companyId, dataField: 'companyId' },
            { condition: 'equal', value: filterStatus.NEW, dataField: 'status' },
          ],
          page: {
            number: 1,
            size: 500,
          },
          searchText: '',
          sort: '-createdAt',
        },
      });

      fetchControlCards({
        filter: {
          fields: [
            { condition: 'equal', value: companyId, dataField: 'companyId' },
            { condition: 'equal', value: [false, null], dataField: 'deviceMatched' },
          ],
          page: {
            number: 1,
            size: 500,
          },
          searchText: '',
          sort: '-createdAt',
        },
      });

      fetchCustomers({
        filter: {
          fields: [{ condition: 'equal', value: companyId, dataField: 'companyId' }],
          page: { number: 1, size: 500 },
          sort: '-createdAt',
        },
      });
    }
  }, [companyId, user.role]); //eslint-disable-line
  useEffect(() => {
    if (device?.controlCardId)
      searchControlCards({
        filter: {
          fields: [{ condition: 'equal', value: device.controlCardId, dataField: '_id' }],
          page: {
            number: 1,
            size: 500,
          },
          searchText: '',
          sort: '-createdAt',
        },
      }).then((response) => {
        if (response?.data?.length) setMatchedControlCard(response?.data[0]);
      });

    searchDeviceBarcodes({
      filter: {
        fields: [{ condition: 'equal', value: id, dataField: 'deviceId' }],
        page: {
          number: 1,
          size: 500,
        },
        searchText: '',
        sort: '-createdAt',
      },
    }).then((response) => {
      console.log(response?.data);
      if (response?.data) setMatchedDeviceBarcodes(response?.data);
    });
  }, [device?._id]); //eslint-disable-line

  const options = [
    { label: '10  ' + translations[appLanguage]['g525'], value: 1000 * 60 * 60 * 24 * 10 },
    { label: '1  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 1 },
    { label: '2  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 2 },
    { label: '3  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 3 },
    { label: '4  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 4 },
    { label: '5  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 5 },
    { label: '6  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 6 },
    { label: '7  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 7 },
    { label: '8  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 8 },
    { label: '9  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 9 },
    { label: '10  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 10 },
    { label: '12  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 12 },
    { label: '18  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 18 },
    { label: '24  ' + translations[appLanguage]['g524'], value: 1000 * 60 * 60 * 24 * 30 * 24 },
  ];

  const deviceBarcode = [...matchedDeviceBarcodes, ...deviceBarcodes]?.find?.(
    (el) => el._id === deviceBarcodeId
  );

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{translations[appLanguage]['g508']}</h3>
      </div>
      {deviceBarcode && (
        <div style={{ margin: '10px' }}>
          <QRCode value={'https://panel.tctege.com/checkDevice/' + deviceBarcode.barcode} />
        </div>
      )}
      <div className={styles.divider}>&nbsp;</div>
      <div style={{ width: '340px' }}>
        <>
          {translations[appLanguage]['g522']}
          <Select
            disabled={device?.status !== 1}
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
            style={{ display: 'flex', flex: 1, margin: '12px 0' }}
            value={customerId}
            onChange={(e) => setCustomerId(e)}
          >
            {customers?.map((el, index) => {
              return (
                <Option key={index} value={el._id}>
                  {(el?.firstName || '') +
                    ' ' +
                    (el?.lastName || ' ') +
                    ' / ' +
                    (el?.email || el?.phone)}
                </Option>
              );
            })}
          </Select>
        </>

        <>
          {translations[appLanguage]['g523']}
          <Select
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={device?.status !== 1}
            allowClear
            style={{ display: 'flex', flex: 1, margin: '12px 0' }}
            value={controlCardId}
            onChange={(e) => setControlCardId(e)}
          >
            {(matchedControlCard ? [matchedControlCard, ...controlCards] : controlCards)?.map(
              (el, index) => {
                return (
                  <Option key={index} value={el._id}>
                    {el?.serialNumber}
                  </Option>
                );
              }
            )}
          </Select>
        </>

        <>
          {translations[appLanguage]['g529']}
          <Select
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
            disabled={device?.status !== 1}
            style={{ display: 'flex', flex: 1, margin: '12px 0' }}
            value={deviceBarcodeId}
            onChange={(e) => setDeviceBarcodeId(e)}
          >
            {[...matchedDeviceBarcodes, ...deviceBarcodes]?.map((el, index) => {
              return (
                <Option key={index} value={el._id}>
                  {el?.barcode}
                </Option>
              );
            })}
          </Select>
        </>

        {user.role === userRole.ADMIN && (
          <>
            {translations[appLanguage]['g204']}
            <Select
              disabled={device?.status !== 1}
              allowClear
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              style={{ display: 'flex', flex: 1, margin: '12px 0' }}
              value={companyId}
              onChange={(e) => setCompanyId(e)}
            >
              {companies?.map((el, index) => {
                return (
                  <Option key={index} value={el._id}>
                    {el?.name}
                  </Option>
                );
              })}
            </Select>
          </>
        )}
        {device?.status !== 1 && (
          <div>
            <Checkbox checked={isActive} onChange={({ target }) => setIsActive(true)}>
              {translations[appLanguage]['g458'] + ' ' + translations[appLanguage]['g352']}
            </Checkbox>

            <Checkbox checked={!isActive} onChange={({ target }) => setIsActive(false)}>
              {translations[appLanguage]['g458'] + ' ' + translations[appLanguage]['g353']}
            </Checkbox>
          </div>
        )}
        <div> {translations[appLanguage]['g521']}</div>
        {filters
          .filter((el) => el.required)
          .map((el) => {
            return (
              <div>
                <>
                  <div> {translations[appLanguage]['g_filterType' + el.type] + ':'}</div>
                  {deviceBarcodeId && (
                    <Select
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      disabled={device?.status !== 1}
                      style={{ display: 'flex', flex: 1, margin: '12px 0' }}
                      value={el.id}
                      onChange={(e) => {
                        filters.find((filter) => filter.type === el.type).id = e;
                        setFilters([...filters]);
                      }}
                    >
                      {[...filterBarcodes, ...matchedFilters]
                        ?.filter((el2) => el2.type === el.type)
                        ?.map((el, index) => {
                          return (
                            <Option key={index} value={el._id}>
                              {el?.barcode}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                  {(el.id || !deviceBarcodeId) && (
                    <div>
                      <Radio.Group
                        options={options}
                        disabled={device?.status !== 1}
                        onChange={(e) => {
                          filters.find((filter) => filter.type === el.type).lifeTime =
                            e.target.value;
                          setFilters([...filters]);
                        }}
                        value={el.lifeTime}
                        optionType='button'
                        buttonStyle='solid'
                      />
                    </div>
                  )}
                </>
              </div>
            );
          })}
        <div style={{ marginTop: '10px' }}>
          <Button
            onClick={() => {
              if (device.status === 1)
                editDevice(
                  id,
                  { filters, customerId, controlCardId, companyId, deviceBarcodeId },
                  () =>
                    history.push(
                      user.role === userRole.ADMIN ? '/admin/devices' : '/companyOwner/devices'
                    )
                );
              else
                editAssembledDevice(id, { isActive }, () =>
                  history.push(
                    user.role === userRole.ADMIN ? '/admin/devices' : '/companyOwner/devices'
                  )
                );
            }}
          >
            {translations[appLanguage]['g22']}
          </Button>
        </div>
        <div style={{ marginTop: '10px' }}>
          <Button
            onClick={async () => {
              setBarcodeExcel(await createBarcodeExcel(id));
            }}
          >
            {translations[appLanguage]['g559']}
          </Button>
        </div>
      </div>
      {barcodeExcel && (
        <div>
          <a href={barcodeExcel}>{translations[appLanguage]['g560']}</a>
        </div>
      )}
    </div>
  );
}
