import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import {
  BankOutlined,
  BoxPlotOutlined,
  DollarOutlined,
  InsertRowLeftOutlined,
  MenuOutlined,
  PlusCircleOutlined,
  RobotOutlined,
  ToolOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';

// contexts
import { AuthContext, LanguageContext } from 'context';

// components
import SidebarModal from 'ui/components/sidebar-modal';
import ProfileModal from 'ui/components/profile-modal';
import { translations } from 'resources';
import {
  HomeOutlined,
  SettingOutlined,
  ShoppingOutlined,
  ShoppingCartOutlined,
  InsertRowBelowOutlined,
  ImportOutlined,
  InboxOutlined,
  ShopOutlined,
  TeamOutlined,
  FileTextOutlined,
  SendOutlined,
  UnorderedListOutlined,
  CheckCircleOutlined,
  FormOutlined,
} from '@ant-design/icons';

// styles
import logo from 'resources/images/blended-logo.png';
import styles from './topbar.module.scss';
import userRole from 'resources/constants/userRole';
import { adminMenu, companyAccountantMenu, companyOwnerMenu, companyUserMenu } from 'helpers/menu';

export default function Topbar() {
  const [showModal, setShowModal] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const {
    state: { user },
  } = useContext(AuthContext);
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);

  const sideBarItemsAdmin = adminMenu(appLanguage);
  const sideBarItemsCompanyOwner = companyOwnerMenu(appLanguage);
  const sideBarItemsCompanyAccountant = companyAccountantMenu(appLanguage);
  const sideBarItemsCompanyUser = companyUserMenu(appLanguage);

  const renderSidebarModal = () => {
    if (!showModal) return;

    return (
      <SidebarModal
        items={
          user?.role === userRole.ADMIN
            ? sideBarItemsAdmin
            : user?.role === userRole.COMPANY_OWNER
            ? sideBarItemsCompanyOwner
            : user?.role === userRole.COMPANY_USER
            ? sideBarItemsCompanyUser
            : sideBarItemsCompanyAccountant
        }
        closeModal={() => setShowModal(false)}
      />
    );
  };

  const renderProfileModal = () => {
    if (!showProfileModal) return;

    return <ProfileModal closeModal={() => setShowProfileModal(false)} />;
  };

  return (
    <>
      {renderSidebarModal()}
      {renderProfileModal()}
      <div className={styles.topbar_container}>
        <div className={styles.logo_container}>
          <Link to='/home'>
            <img src={logo} className={styles.app_logo} alt='logo' />
          </Link>
          <div onClick={() => setShowModal(!showModal)} className={styles.hamburger_menu}>
            <MenuOutlined />
          </div>
        </div>
        <div style={{ display: 'flex', flex: '1 1 0px' }} />
        <div>
          {user?.profile && (
            <div style={{ color: '#ffff', fontSize: '16px', fontWeight: 700 }}>
              {user?.profile?.firstName} {user?.profile?.lastName}
            </div>
          )}
        </div>
        <div className={styles.icons_container}>
          <div
            onClick={() => setShowProfileModal(!showProfileModal)}
            className={styles.icon_container}
          >
            {user?.profile?.profilePicture ? (
              <img
                className={styles.profile_photo}
                alt='Profile'
                src={user?.profile?.profilePicture}
              />
            ) : (
              <UserOutlined />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
