import { useEffect, useRef, useState } from 'react';

const useForm = (initialState, deps) => {
  const formRef = useRef({});

  const getInitialForm = () => {
    const initialForm = Object.keys(initialState).map((key) => ({
      [key]: { value: initialState[key].value },
    }));
    return { form: Object.assign({}, ...initialForm) };
  };

  const [form, setForm] = useState(getInitialForm());

  useEffect(() => {
    Object.keys(initialState).forEach((key) => {
      formRef.current[key] = {
        value: initialState[key].value,
        error: !initialState[key].value && (formRef.current[key]?.error ?? ''),
        getError: initialState[key]?.getError,
        setValue: (value) => {
          formRef.current[key].value = value;
          formRef.current[key].error && (formRef.current[key].error = '');
          setForm({ form: formRef.current });
        },
        setError: (error) => {
          formRef.current[key].error = error;
          setForm({ form: formRef.current });
        },
      };
    });

    formRef.current.validate = () => {
      const keys = Object.keys(formRef.current);
      const isValid = keys.every((key) => {
        return !formRef.current[key].getError?.(formRef.current[key].value);
      });

      if (isValid) {
        return true;
      }
      keys.forEach((key) => {
        formRef.current[key].error = formRef.current[key].getError?.(formRef.current[key].value);
      });
      setForm({ form: formRef.current });
      return false;
    };
    setForm({ form: formRef.current });
  }, deps || []); //eslint-disable-line

  return { ...form };
};

export default useForm;
