import { AuthProvider } from 'context/auth';
import { LanguageProvider } from 'context/language';
import { PasswordProvider } from 'context/password';
import { DeviceProvider } from 'context/device';
import { WorkerProvider } from 'context/worker';
import { CountryProvider } from 'context/country';
import { CompanyProvider } from 'context/company';
import { ApiProvider } from 'context/api';
import { CustomerProvider } from 'context/customer';
import { UserProvider } from 'context/user';
import { ControlCardProvider } from 'context/controlCard';
import { FilterBarcodeProvider } from 'context/filterBarcode';
import { DeviceBarcodeProvider } from 'context/deviceBarcode';
import { PaymentProvider } from 'context/payment';

const providers = [
  AuthProvider,
  LanguageProvider,
  PasswordProvider,
  DeviceProvider,
  WorkerProvider,
  CountryProvider,
  CompanyProvider,
  ApiProvider,
  CustomerProvider,
  UserProvider,
  ControlCardProvider,
  FilterBarcodeProvider,
  DeviceBarcodeProvider,
  PaymentProvider
];

export default providers;
