import { apiURL, axiosClient } from 'service';
import {
  SIGN_IN_USER_SUCCESS,
  SIGN_IN_ERROR,
  SIGN_UP_USER_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_OUT_USER_SUCCESS,
  SET_USER,
  RESET_USER,
} from 'context/auth/keys';
import history from 'history.js';

export const signInUser = (dispatch) => async (user) => {
  const response = (await axiosClient.post(apiURL.signIn, user)).data;

  if (response.success) {
    dispatch({ type: SIGN_IN_USER_SUCCESS, payload: response.user });
    localStorage.setItem('token', response.token);
    history.push('/home');
  } else {
    dispatch({ type: SIGN_IN_ERROR, payload: response.error });
  }
};

export const signUpUser = (dispatch) => async (user) => {
  const response = (await axiosClient.post(apiURL.signUp, user)).data;

  if (response.success) {
    dispatch({ type: SIGN_UP_USER_SUCCESS, payload: response.user });
    localStorage.setItem('token', response.token);
    history.push('/home');
  } else {
    dispatch({ type: SIGN_UP_ERROR, payload: response.error });
  }
};

export const signOut = (dispatch) => async () => {
  dispatch({ type: SIGN_OUT_USER_SUCCESS });
  localStorage.removeItem('token');
  history.push('/');
};

export const getUser = (dispatch) => async () => {
  const { data } = await axiosClient.get(apiURL.account, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  });

  if (data?.email) {
    dispatch({ type: SET_USER, payload: data });
  } else {
    localStorage.removeItem('token');
    history.push('/');
  }
};

export const pushUser = (dispatch) => async (user) => {
  const { data } = await axiosClient.put(apiURL.account, user, {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
  });

  if (data.email) {
    dispatch({ type: SET_USER, payload: data });
  }
};

export const setUser = (dispatch) => async (user) => {
  dispatch({ type: SET_USER, payload: user });
};

export const resetUser = (dispatch) => async () => {
  dispatch({ type: RESET_USER });
};
