import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { readAndCompressImage } from 'browser-image-resizer';
import { CameraOutlined, UserOutlined } from '@ant-design/icons';

import { LanguageContext, AuthContext } from 'context';
import { translations } from 'resources';
import { apiURL, axiosClient } from 'service';
import styles from './profile-modal.module.scss';

export default function ProfileModal(props) {
  const {
    signOut,
    resetUser,
    setUser,
    state: { user },
  } = useContext(AuthContext);
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);

  //TODO: move them to context
  const resizeFiles = async (files, maxWidth, maxHeight) => {
    const config = {
      quality: 1,
      maxWidth: maxWidth || 2048,
      maxHeight: maxHeight || 2048,
      mimeType: 'image/jpeg',
      // autoRotate: true,
      // debug: true
    };
    let fileLen = files.length;
    for (let a = 0; a < fileLen; a++) {
      let resizedImage = await readAndCompressImage(files[a], config);
      let extensionTrim = files[a].name.substring(files[a].name.lastIndexOf('.'));
      extensionTrim = files[a].name.replace(extensionTrim, '');
      resizedImage = new File([resizedImage], 'profilePhoto.jpg', {
        type: files[a].type,
      });
      files[a] = resizedImage;
    }
    return files;
  };
  //TODO: move them to context
  const fileUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const config = {
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      onUploadProgress: (ProgressEvent) => {},
    };
    let response = await axiosClient.put(apiURL.profilePhoto, formData, config);
    return response.data;
  };
  //TODO: move them to context
  const onFileChange = async (files) => {
    const fileArray = [];
    if (!files?.length) return;
    for (let i = 0; i < files.length; i++) fileArray.push(files.item(i));
    files = await resizeFiles(fileArray, 1024, 1024);

    if (files?.[0]) {
      var file = files[0];
      const photoUrl = await fileUpload(file);
      setUser({ ...user, photoUrl });
      // setPhotoQuery(new Date());
    }
  };

  return ReactDOM.createPortal(
    <div onClick={props.closeModal} className={styles.modal_container}>
      <div onClick={(e) => e.stopPropagation()} className={styles.profile_card_container}>
        <div className={styles.icons_container}>
          <div className={styles.icon_container}>
            {user?.profile?.profilePicture ? (
              <img
                className={styles.profile_photo}
                alt='Profile'
                src={user?.profile?.profilePicture}
              />
            ) : (
              <UserOutlined />
            )}
          </div>
          <div className={styles.photo_icon}>
            <label>
              <input type='file' onChange={({ target: { files } }) => onFileChange(files)} />
              <CameraOutlined />
            </label>
          </div>
        </div>
        <div className={styles.name_info_container}>
          <div className={styles.name_text}>
            {(user.firstName || '') + ' ' + (user.lastName || '')}
          </div>
          <div className={styles.mail_text}>{user.email || ''}</div>
        </div>
        <div className={styles.spacer} />
        <div className={styles.signout_container}>
          <Link
            onClick={() => {
              signOut();
              resetUser();
            }}
            to='/'
          >
            <span>{translations[appLanguage]['g24']}</span>
          </Link>
        </div>
      </div>
    </div>,
    document.querySelector('#modal')
  );
}
