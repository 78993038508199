import { PASSWORD_CHANGE_SUCCESS, PASSWORD_CHANGE_ERROR } from 'context/password/keys';

export const initialState = {
  isPasswordChangeSuccess: false,
  passwordChangeError: '',
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        isPasswordChangeSuccess: true,
        passwordChangeError: '',
      };
    case PASSWORD_CHANGE_ERROR:
      return { ...state, passwordChangeError: payload };

    default:
      return state;
  }
};

export default reducer;
