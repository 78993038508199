import {
  FETCH_USERS_ERROR,
  FETCH_USERS_SUCCESS,
  FETCH_USER_ERROR,
  FETCH_USER_SUCCESS,
  SET_LOADING,
} from 'context/user/keys';

export const initialState = {
  users: [],
  errorMessage: '',
  user: {},
  totalUserNumber: 0,
  totalCount: 0,
  loading: true,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        users: [...payload.data],
        totalUserNumber: payload.totalCount,
        loading: false,
      };
    case FETCH_USERS_ERROR:
      return { ...state, errorMessage: payload };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        user: payload,
        loading: false,
      };
    case FETCH_USER_ERROR:
      return { ...state, errorMessage: payload };
    default:
      return state;
  }
};

export default reducer;
