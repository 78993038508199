import {
  FETCH_WORKERS_SUCCESS,
  FETCH_WORKERS_ERROR,
  FETCH_WORKER_SUCCESS,
  FETCH_WORKER_ERROR,
  ADD_WORKER_SUCCESS,
  ADD_WORKER_ERROR,
  RESET_WORKER,
  UPDATE_WORKER_SUCCESS,
  UPDATE_WORKER_ERROR,
  SET_LOADING,
} from 'context/worker/keys';

export const initialState = {
  workers: [],
  errorMessage: '',
  worker: {},
  totalWorkerNumber: 0,
  loading: true,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_WORKERS_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        workers: payload.data,
        totalWorkerNumber: payload.totalCount,
        loading: false,
      };
    case FETCH_WORKERS_ERROR:
      return { ...state, errorMessage: payload, loading: false };
    case FETCH_WORKER_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        worker: { ...state.worker, ...payload },
        loading: false,
      };
    case FETCH_WORKER_ERROR:
      return { ...state, errorMessage: payload, loading: false };
    case ADD_WORKER_SUCCESS:
      return { ...state, errorMessage: '', workers: [payload.user, ...state.workers] };
    case ADD_WORKER_ERROR:
      return { ...state, errorMessage: payload };
    case UPDATE_WORKER_SUCCESS:
      return { ...state, errorMessage: '', worker: payload };
    case UPDATE_WORKER_ERROR:
      return { ...state, errorMessage: payload };
    case RESET_WORKER:
      return { ...state, worker: {} };
    default:
      return state;
  }
};

export default reducer;
