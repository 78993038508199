import React, { useContext, useRef } from 'react';
import { toast, Slide } from 'react-toastify';

// components
import Button from 'ui/reusables/button';
import Input from 'ui/reusables/input';

// contexts
import { LanguageContext, PasswordContext } from 'context';

// helpers
import { translations } from 'resources';
import { isEmpty, isValidPassword } from 'utils';
import { useForm } from 'hooks';

import styles from './password-change.module.scss';

export default function PasswordChange() {
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { passwordChangeError },
    changePassword,
  } = useContext(PasswordContext);

  const currentPasswordInput = useRef();
  const newPasswordInput = useRef();
  const confirmNewPasswordInput = useRef();

  const {
    form: { currentPassword, newPassword, confirmNewPassword, validate },
  } = useForm({
    currentPassword: {
      value: '',
      getError: (value) => (isEmpty(value) ? `${translations[appLanguage]['g134']}` : ''),
    },
    newPassword: {
      value: '',
      getError: (value) => (isValidPassword(value) ? '' : `${translations[appLanguage]['g7']}`),
    },
    confirmNewPassword: {
      value: '',
      getError: (value) => (isValidPassword(value) ? '' : `${translations[appLanguage]['g7']}`),
    },
  });

  const handleFormSubmission = (event) => {
    event.preventDefault();
    if (!validate()) return;
    if (confirmNewPassword.value === newPassword.value) {
      changePassword(
        {
          currentPassword: currentPassword.value,
          newPassword: newPassword.value,
        },
        notify,
        '/customer/settings'
      );
    } else {
      confirmNewPassword.setError(`${translations[appLanguage]['g26']}`);
      newPassword.setError(`${translations[appLanguage]['g26']}`);
    }
  };

  const notify = () =>
    toast.success(`${translations[appLanguage]['g43']}`, {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      transition: Slide,
    });

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{translations[appLanguage]['g21']}</h3>
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <form onSubmit={handleFormSubmission} className={styles.form_container}>
        <div className={styles.input_container}>
          <Input
            label={translations[appLanguage]['g42']}
            forwardRef={currentPasswordInput}
            value={currentPassword?.value}
            onChange={(e) => currentPassword?.setValue(e.target.value)}
          />
          <div className={styles.error_message}>
            {currentPassword?.error ? currentPassword?.error : <span>&nbsp;</span>}
          </div>
        </div>
        <div className={styles.input_container}>
          <Input
            label={translations[appLanguage]['g37']}
            forwardRef={newPasswordInput}
            value={newPassword?.value}
            onChange={(e) => newPassword?.setValue(e.target.value)}
          />
          <div className={styles.error_message}>
            {newPassword?.error ? newPassword?.error : <span>&nbsp;</span>}
          </div>
        </div>
        <div className={styles.input_container}>
          <Input
            label={translations[appLanguage]['g38']}
            forwardRef={confirmNewPasswordInput}
            value={confirmNewPassword?.value}
            onChange={(e) => confirmNewPassword?.setValue(e.target.value)}
          />
          <div className={styles.error_message}>
            {confirmNewPassword?.error ? confirmNewPassword?.error : <span>&nbsp;</span>}
          </div>
        </div>
        <div className={styles.error_message}>{passwordChangeError}</div>
        <div className={styles.button_container}>
          <Button onClick={handleFormSubmission} type='save'>
            {translations[appLanguage]['g22']}
          </Button>
        </div>
      </form>
    </div>
  );
}
