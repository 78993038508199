import { apiURL, axiosClient } from 'service';
import {
  CREATE_FILTER_BARCODE_ERROR,
  CREATE_FILTER_BARCODE_SUCCESS,
  FETCH_FILTER_BARCODES_ERROR,
  FETCH_FILTER_BARCODES_SUCCESS,
  FETCH_FILTER_BARCODE_ERROR,
  FETCH_FILTER_BARCODE_SUCCESS,
  RESET,
  SET_LOADING,
  UPDATE_FILTER_BARCODE_ERROR,
  UPDATE_FILTER_BARCODE_SUCCESS,
} from 'context/filterBarcode/keys';
import history from 'history.js';

export const fetchFilterBarcodes = (dispatch) => async (deviceFilter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.filterBarcodes, deviceFilter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_FILTER_BARCODES_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_FILTER_BARCODES_ERROR, payload: response.error });
  }
};

export const searchFilterBarcodes = (dispatch) => async (deviceFilter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.filterBarcodes, deviceFilter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  return response;
};

export const getFilterBarcode = (dispatch) => async (deviceId) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.get(`${apiURL.filterBarcode}/${deviceId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_FILTER_BARCODE_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_FILTER_BARCODE_ERROR, payload: response.error });
  }
};

export const updateFilterBarcode = (dispatch) => async (id, filterBarcode, callBack) => {
  const response = (
    await axiosClient.put(`${apiURL.filterBarcode}/${id}`, filterBarcode, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: UPDATE_FILTER_BARCODE_SUCCESS, payload: response });
    if (callBack) callBack();
    // history.push('/admin/companies');
  } else {
    dispatch({ type: UPDATE_FILTER_BARCODE_ERROR, payload: response.error });
  }
};

export const createFilterBarcode = (dispatch) => async (filterBarcode, callBack) => {
  const response = (
    await axiosClient.post(apiURL.filterBarcode, filterBarcode, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: CREATE_FILTER_BARCODE_SUCCESS, payload: response });
    if (callBack) callBack();
    // history.push('/admin/companies');
  } else {
    dispatch({ type: CREATE_FILTER_BARCODE_ERROR, payload: response.error });
  }
};
export const resetFilterBarcode = (dispatch) => async () => {
  dispatch({ type: RESET });
};
