import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Select, Table } from 'antd';

// contexts
import {
  LanguageContext,
  PaymentContext,
  CompanyContext,
  AuthContext,
  CustomerContext,
} from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import Input from 'ui/reusables/input';
import { getParam } from 'helpers/page';
import Search from 'ui/components/search/search';
import history from 'history.js';
import userRole from 'resources/constants/userRole';
import { paymentType } from 'resources/constants/payment';

const { Option } = Select;
export default function AddPayment(props) {
  const { id } = props.match.params;
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { payment, loading },
    createPayment,
    getPayment,
    updatePayment,
  } = useContext(PaymentContext);
  const {
    state: { customers },
    fetchCustomers,
  } = useContext(CustomerContext);

  const {
    state: { companies },
    fetchCompanies,
  } = useContext(CompanyContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  const [companyId, setCompanyId] = useState();
  const [customerId, setCustomerId] = useState();
  const [note, setNote] = useState('');
  const [amount, setAmount] = useState('');
  const [type, setType] = useState(paymentType.PAYMENT);

  useEffect(() => {
    if (id) getPayment(id);
  }, [id]); //eslint-disable-line

  useEffect(() => {
    if (user.role !== userRole.ADMIN) fetchCustomers();
    else fetchCompanies();
  }, [user.role]); //eslint-disable-line
  useEffect(() => {
    if (companyId)
      fetchCustomers({
        filter: {
          fields: [{ condition: 'equal', value: companyId, dataField: 'companyId' }],
          page: {
            number: 1,
            size: 500,
          },
          searchText: '',
          sort: '-createdAt',
        },
      });
  }, [user.role, companyId]); //eslint-disable-line

  useEffect(() => {
    if (id && payment?._id === id) {
      setAmount(payment.amount);
      setCompanyId(payment.companyId);
      setCustomerId(payment.customerId);
      setNote(payment.note);
    }
  }, [payment]); //eslint-disable-line

  const getUrlPrefix = useCallback(() => {
    if (user.role === userRole.ADMIN) return '/admin';
    else if (user.role === userRole.COMPANY_OWNER) return '/companyOwner';
    else if (user.role === userRole.COMPANY_USER) return '/companyUser';
    else if (user.role === userRole.COMPANY_ACCOUNTANT) return '/companyAccountant';
    else if (user.role === userRole.WORKER) return '/worker';
  }, [user.role]);

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{id ? translations[appLanguage]['g552'] : translations[appLanguage]['g551']}</h3>
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <div style={{ width: '300px' }}>
        <Input
          label={translations[appLanguage]['g553']}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <>
          <>{translations[appLanguage]['g427']}</>
          <Select
            style={{ display: 'flex', flex: 1, margin: '12px 0' }}
            value={type}
            onChange={(e) => setType(e)}
          >
            {Object.keys(paymentType)?.map((el, index) => {
              return (
                <Option key={index} value={paymentType[el]}>
                  {translations[appLanguage]['g_paymentType' + paymentType[el]]}
                </Option>
              );
            })}
          </Select>
        </>
        <Input
          label={translations[appLanguage]['g304']}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <>
          <>{translations[appLanguage]['g122']}</>
          <Select
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
            style={{ display: 'flex', flex: 1, margin: '12px 0' }}
            value={customerId}
            onChange={(e) => setCustomerId(e)}
          >
            {customers?.map((el, index) => {
              return (
                <Option key={index} value={el._id}>
                  {(el?.firstName || '') +
                    ' ' +
                    (el?.lastName || ' ') +
                    ' / ' +
                    (el?.email || el?.phone)}
                </Option>
              );
            })}
          </Select>
        </>
        {user.role === userRole.ADMIN && (
          <>
            <>{translations[appLanguage]['g204']}</>
            <Select
              style={{ display: 'flex', flex: 1, margin: '12px 0' }}
              value={companyId}
              onChange={(e) => setCompanyId(e)}
            >
              {companies?.map((el, index) => {
                return (
                  <Option key={index} value={el._id}>
                    {el?.name}
                  </Option>
                );
              })}
            </Select>
          </>
        )}

        <Button
          onClick={() => {
            const payment = { customerId, companyId, note, amount, type };
            id
              ? updatePayment(id, payment, () => history.push(getUrlPrefix() + '/payments'))
              : createPayment(payment, () => history.push(getUrlPrefix() + '/payments'));
          }}
        >
          {id ? translations[appLanguage]['g552'] : translations[appLanguage]['g551']}
        </Button>
      </div>
      {/* <Search onChange={setFilter} />
      <div className={styles.table_container}>
        <Table
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setVisible(true);
                setCurrentProduct(record);
              },
            };
          }}
          size={'middle'}
          loading={loading && !data?.length}
          bordered
          columns={columns}
          dataSource={data}
          scroll={{ x: 600, y: calcViewWidth > 995 ? calcViewHeight - 310 : calcViewHeight - 260 }}
          expandable
          pagination={{ onChange: onPageChange, current: pageIndex, pageSize }}
        />
      </div> */}
    </div>
  );
}
