import React from 'react';
import ReactDOM from 'react-dom';
import { NavLink } from 'react-router-dom';
import Icon from '@ant-design/icons';

import Dropdown from 'ui/reusables/dropdown';
import logo from 'resources/images/blended-logo.png';
import styles from './sidebar-modal.module.scss';

export default function SidebarModal({ closeModal, items = [] }) {
  return ReactDOM.createPortal(
    <div onClick={closeModal} className={styles.modal_container}>
      <div onClick={(e) => e.stopPropagation()} className={styles.sidebar_container}>
        <NavLink className={styles.logo_container} to='/main'>
          <img src={logo} className={styles.app_logo} alt='logo' />
        </NavLink>
        <nav className={styles.menu_list_container}>
          {items.map((item, index) => {
            if (item.dropdown) {
              return (
                <Dropdown
                  key={`dd-${index}`}
                  menuIcon={item.icon}
                  menuName={item.name}
                  menuItems={item.menuItems}
                />
              );
            } else {
              return (
                <NavLink
                  onClick={() => {
                    closeModal();
                  }}
                  key={`nl-${index}`}
                  to={item.url}
                  activeStyle={{ color: 'salmon' }}
                >
                  <Icon component={item.icon} className={styles.icon} />
                  <span>{item.name}</span>
                </NavLink>
              );
            }
          })}
        </nav>
      </div>
    </div>,
    document.querySelector('#modal')
  );
}
