import React from 'react';
import { NavLink } from 'react-router-dom';
import Icon from '@ant-design/icons';

// reusables
import Dropdown from 'ui/reusables/dropdown';

// styles
import styles from './sidebar.module.scss';

export default function Sidebar({ items = [] }) {
  return (
    <div className={styles.sidebar_container}>
      <nav className={styles.menu_list_container}>
        {items.map((item, index) => {
          if (item.dropdown) {
            return (
              <Dropdown
                key={`dd-${index}`}
                menuIcon={item.icon}
                menuName={item.name}
                menuItems={item.menuItems}
              />
            );
          } else {
            return (
              <NavLink key={`nl-${index}`} to={item.url} activeStyle={{ color: '#04798f' }}>
                <Icon component={item.icon} className={styles.icon} />
                <span>{item.name}</span>
              </NavLink>
            );
          }
        })}
      </nav>
    </div>
  );
}
