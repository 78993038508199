import {
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_ERROR,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_ERROR,
  RESET_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
  SET_LOADING,
  FETCH_COMPANY_SUCCESS,
  REQUEST_ERROR,
  FETCH_COMPANY_ERROR,
} from 'context/company/keys';

export const initialState = {
  companies: [],
  company: {},
  errorMessage: '',
  totalCompanyNumber: 0,
  loading: true,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: '',
        companies: payload?.data,
        totalCompanyNumber: payload?.totalCount,
      };
    case FETCH_COMPANIES_ERROR:
      return { ...state, loading: false, errorMessage: payload };
    case REQUEST_ERROR:
      return { ...state, loading: false, errorMessage: payload };
    case FETCH_COMPANY_SUCCESS:
      return { ...state, errorMessage: '', company: payload };
    case FETCH_COMPANY_ERROR:
      return { ...state, errorMessage: payload };
    case ADD_COMPANY_SUCCESS:
      return { ...state, errorMessage: '', company: payload };
    case ADD_COMPANY_ERROR:
      return { ...state, errorMessage: payload };
    case UPDATE_COMPANY_SUCCESS:
      return { ...state, errorMessage: '', company: payload?.data };
    case UPDATE_COMPANY_ERROR:
      return { ...state, errorMessage: payload };
    case RESET_COMPANY:
      return { ...state, company: {} };
    default:
      return state;
  }
};

export default reducer;
