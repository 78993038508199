import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Select, Table } from 'antd';
import QRCode from 'react-qr-code';

// contexts
import { LanguageContext, DeviceBarcodeContext, CompanyContext, AuthContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import Input from 'ui/reusables/input';
import { getParam } from 'helpers/page';
import history from 'history.js';
import userRole from 'resources/constants/userRole';
import { filterType } from 'resources/constants/filterType';

const { Option } = Select;
export default function AddDeviceBarcode(props) {
  const { id } = props.match.params;
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { deviceBarcode, loading },
    createDeviceBarcode,
    getDeviceBarcode,
    updateDeviceBarcode,
    resetDeviceBarcode,
  } = useContext(DeviceBarcodeContext);

  const {
    state: { companies },
    fetchCompanies,
  } = useContext(CompanyContext);
  const {
    state: { user },
  } = useContext(AuthContext);

  const [companyId, setCompanyId] = useState();
  const [quantity, setQuantity] = useState(1);

  useEffect(() => {
    if (id) getDeviceBarcode(id);
    else {
      console.log('resetfilterbarcode');
      resetDeviceBarcode();
    }
  }, [id]); //eslint-disable-line
  useEffect(() => {
    fetchCompanies();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (id && deviceBarcode?._id === id) {
      setCompanyId(deviceBarcode.companyId);
    }
  }, [deviceBarcode]); //eslint-disable-line

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3> {id ? translations[appLanguage]['g527'] : translations[appLanguage]['g526']}</h3>
      </div>
      {deviceBarcode?.barcode && (
        <div style={{ margin: '10px' }}>
          <QRCode value={'https://panel.tctege.com/checkDevice/' + deviceBarcode.barcode} />
        </div>
      )}
      <div className={styles.divider}>&nbsp;</div>
      <div style={{ width: '300px' }}>
        <Input
          label={translations[appLanguage]['g207']}
          value={
            deviceBarcode?.status
              ? translations[appLanguage]['g_filterStatus' + deviceBarcode?.status]
              : '--'
          }
          disabled
        />
        <Input
          label={translations[appLanguage]['g328']}
          value={deviceBarcode?.barcode || '--'}
          disabled
        />

        {user.role === userRole.ADMIN && (
          <>
            <>{translations[appLanguage]['g204']}</>
            <Select
              style={{ display: 'flex', flex: 1, margin: '12px 0' }}
              value={companyId}
              onChange={(e) => setCompanyId(e)}
            >
              {companies?.map((el, index) => {
                return (
                  <Option key={index} value={el._id}>
                    {el?.name}
                  </Option>
                );
              })}
            </Select>
          </>
        )}
        {!id && (
          <div style={{ width: '300px', marginBottom: '10px' }}>
            <Input
              label={translations[appLanguage]['g547']}
              value={quantity}
              onChange={(e) =>
                parseFloat(e.target.value) > 20 ? setQuantity(20) : setQuantity(e.target.value)
              }
            />
          </div>
        )}

        <Button
          disabled={!id ? !(quantity >= 1) : false}
          onClick={() => {
            const deviceBarcode = { companyId, quantity: parseFloat(quantity) };
            const url =
              user.role === userRole.ADMIN
                ? '/admin/deviceBarcodes'
                : '/companyOwner/deviceBarcodes';
            id
              ? updateDeviceBarcode(id, deviceBarcode, () => history.push(url))
              : createDeviceBarcode(deviceBarcode, () => history.push(url));
          }}
        >
          {id ? translations[appLanguage]['g527'] : translations[appLanguage]['g526']}
        </Button>
      </div>
    </div>
  );
}
