import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Select, Tabs, List } from 'antd';

// contexts
import {
  LanguageContext,
  CustomerContext,
  PaymentContext,
  AuthContext,
  DeviceContext,
} from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import Input from 'ui/reusables/input';
import { getParam } from 'helpers/page';
import Search from 'ui/components/search/search';
import history from 'history.js';
import userRole from 'resources/constants/userRole';
import { paymentType } from 'resources/constants/payment';
const { TabPane } = Tabs;
const { Option } = Select;
export default function CustomerDetail(props) {
  const { id } = props.match.params;
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { customer, loading },
    createCustomer,
    getCustomer,
    updateCustomer,
  } = useContext(CustomerContext);
  const {
    state: { payments },
    fetchPayments,
  } = useContext(PaymentContext);

  const {
    state: { user },
  } = useContext(AuthContext);
  const {
    state: { devices },
    fetchDevices,
  } = useContext(DeviceContext);

  useEffect(() => {
    if (id) getCustomer(id);
  }, [id]); //eslint-disable-line

  useEffect(() => {
    if (customer?._id) {
      fetchDevices({
        filter: {
          fields: [{ condition: 'equal', value: customer._id, dataField: 'customerId' }],
          page: {
            number: 1,
            size: 500,
          },
          searchText: '',
          sort: '-createdAt',
        },
      });
      fetchPayments({
        filter: {
          fields: [{ condition: 'equal', value: customer._id, dataField: 'customerId' }],
          page: {
            number: 1,
            size: 500,
          },
          searchText: '',
          sort: '-createdAt',
        },
      });
    }
  }, [customer]); //eslint-disable-line

  const getUrlPrefix = useCallback(() => {
    if (user.role === userRole.ADMIN) return '/admin';
    else if (user.role === userRole.COMPANY_OWNER) return '/companyOwner';
    else if (user.role === userRole.COMPANY_USER) return '/companyUser';
    else if (user.role === userRole.COMPANY_ACCOUNTANT) return '/companyAccountant';
    else if (user.role === userRole.WORKER) return '/worker';
  }, [user.role]);

  const data = devices.map((el) => ({ ID: el.ID, _id: el._id }));

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{translations[appLanguage]['g562']}</h3>
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <div>{customer?.firstName + customer?.lastName}</div>
      <div>{translations[appLanguage]['g1'] + ' : ' + customer?.phone}</div>
      <div>{translations[appLanguage]['g155'] + ' : ' + customer?.address}</div>
      <div>{translations[appLanguage]['g178'] + ' : ' + customer?.district}</div>
      <div>{translations[appLanguage]['g177'] + ' : ' + customer?.city}</div>
      <div>{translations[appLanguage]['g204'] + ' : ' + customer?.company?.fullName}</div>

      <Tabs defaultActiveKey='1'>
        <TabPane tab={translations[appLanguage]['g412']} key='1'>
          <List
            size='large'
            bordered
            dataSource={data}
            renderItem={(item) => (
              <List.Item>
                <a href={getUrlPrefix() + '/editDevice/' + item._id}>
                  {item.ID +
                    (item.deviceBarcode?.barcode ? ' / ' + item.deviceBarcode?.barcode : '')}
                </a>
              </List.Item>
            )}
          />
        </TabPane>
        <TabPane tab={translations[appLanguage]['g549']} key='2'>
          <List
            size='large'
            header={
              <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
                <div>{translations[appLanguage]['g427']}</div>
                <div style={{ width: '250px' }}>{translations[appLanguage]['g304']}</div>
                <div >{translations[appLanguage]['g553']}</div>
                <div>{translations[appLanguage]['g17']}</div>
              </div>
            }
            footer={
              <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  {translations[appLanguage]['g563'] +
                    ' : ' +
                    payments.reduce(
                      (p, c) => p + (c.type === paymentType.PAYMENT ? c.amount : -c.amount),
                      0
                    )}
                </div>
              </div>
            }
            bordered
            dataSource={payments}
            renderItem={(item) => (
              <List.Item>
                <div style={{ width: '500px', display: 'flex', justifyContent: 'space-between' }}>
                  <div>{translations[appLanguage]['g_paymentType' + item.type]}</div>
                  <div style={{ width: '300px', wordWrap: 'break-word' }}>{item.note}</div>
                  <div>{item.amount}</div>
                  <div>{new Date(item.createdAt).toLocaleDateString()}</div>
                </div>
              </List.Item>
            )}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}
