import { getPrinter, sendImage, sendZpl } from 'lib/printer';
import { axiosClient, apiURL } from 'service';

// const print = async (uri) => {
//   // get link PDF and create instance of pdfJsLib
//   const loadPdf = pdfjsLib.getDocument(uri);
//   // await deserialization of PDF
//   const PDFContent = await loadPdf.promise();
//   // await load page
//   const page = await PDFContent.getPage(1);
//   // now await styles and text itens to PDF
//   const pdf = await page.getTextContent();
//   // Verify exists itens on PDF
//   if (!pdf.items || pdf.items.length) return;
//   // get scale of print
//   const scale = pdf.items
//     .map((item) => {
//       const [, , , , , topPosition] = item.transform;
//       return topPosition;
//     })
//     .reduce((transform, nextTransform) => Math.min(transform, nextTransform));
//   // create content for print.
//   let content =
//     '^XA~TA000~JSN^LT0^MNW^MTT^PON^PMN^LH0,0^JMA^PR5,5~SD15^JUS^LRN^CI0^XZ^XA^MMT^PW831^LL0480^LS0';

//   // loop data for add itens into content;
//   pdf.items.forEach((item) => {
//     const [fontSize, , , fontWeight, initialPosition, topPosition] = item.transform;
//     content += `^FT
//                 ${800 - initialPosition},
//                 ${topPosition - scale}
//                 ^A0I,
//                 ${fontSize},
//                 ${fontWeight}
//                 ^FB$
//                 {item.width},
//                 1,0,C^FH^FD$
//                 {(item.str.normalize('NFD').replace(/[\u0300-
//                    \u036f]/g, ''))}
//                 ^FS`;
//   });
//   // add finish content
//   content += '^PQ1,0,1,Y^XZ;';
//   // create a new XHR request
//   const http = new XMLHttpRequest();
//   // set method type and ip address of zebra printer
//   http.open('POST', `http//${ip_printer}/printer/pstprnt`, true);
//   // add content-type
//   http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
//   // sending request with a print test.
//   http.send(content);
// };

const Barcode = {
  print: async (barcode, printProfile, options = {}) => {
    const { marginLeft, marginRight, marginTop, marginBottom, rotate } = options;
    let printerData;
    try {
      printerData = JSON.parse(localStorage.getItem('printerData' + printProfile));
    } catch (er) {
      printerData = {};
    }

    const printer = await getPrinter(printerData?.device);
    if (!printer) return alert('Lütfen  Yazıcı Ayarlarını yapınız');

    const url = await axiosClient.post(
      apiURL.getImage,
      {
        item: {
          type: 'barcode',
          data: barcode,
          options: {
            marginLeft: (marginLeft || 0) + (printerData.marginLeft || 0),
            marginTop: (marginTop || 0) + (printerData.marginTop || 0),
            marginRight,
            marginBottom,
            rotate,
          },
        },
        format: '.png',
        options: {
          background: '0xffffffff',
        },
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );

    sendImage(url.data, printer);
  },
  printPdf: async (pdfUrl, printProfile, options = {}) => {
    const { width, height } = options;

    let printerData;
    try {
      printerData = JSON.parse(localStorage.getItem('printerData' + printProfile));
    } catch (er) {
      printerData = {};
    }

    const printer = await getPrinter(printerData?.device);
    if (!printer) return alert('Lütfen  Yazıcı Ayarlarını yapınız');

    const url = await axiosClient.post(
      apiURL.pdfToUrl,
      {
        density: 100,
        pdfUrl,
        format: 'bmp',
        width: width || 860,
        height: height || 1450,
        convertPages: [1],
        marginLeft: printerData.marginLeft || 0,
        marginTop: printerData.marginTop || 50,
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );

    sendImage(url.data[0], printer);
    // sendFile(url.data[0], printer);
  },
  printZpl: async (zpl, printProfile, options = {}) => {
    let printerData;
    try {
      printerData = JSON.parse(localStorage.getItem('printerData' + printProfile));
    } catch (er) {
      printerData = {};
    }

    const printer = await getPrinter(printerData?.device);
    if (!printer) return alert('Lütfen  Yazıcı Ayarlarını yapınız');
    options.marginTop = (options.marginTop || 0) + (printerData.marginTop || 0);
    if (zpl.indexOf('^XA') >= 0) {
      const firstPart = zpl.substring(0, zpl.indexOf('^XA') + 3);
      const secondPart = zpl.substring(zpl.indexOf('^XA') + 3);
      zpl = firstPart + '^LT=' + options.marginTop + secondPart;
    }

    if (zpl.indexOf('FVShipper:^FS') >= 0) {
      const firstPart = zpl.substring(0, zpl.indexOf('FVShipper:^FS') + 13);
      const secondPart = zpl.substring(zpl.indexOf('FVShipper:^FS') + 13);
      zpl = firstPart + '^CI28' + secondPart;
    }
    console.log('yesss', zpl);

    sendZpl(zpl, printer);
    // sendFile(url.data[0], printer);
  },
  printMultiple: async (items, printProfile, options = {}) => {
    let printerData;

    try {
      printerData = JSON.parse(localStorage.getItem('printerData' + printProfile));
    } catch (er) {
      printerData = {};
    }

    const printer = await getPrinter(printerData?.device);
    if (!printer) return alert('Lütfen  Yazıcı Ayarlarını yapınız');

    options.marginLeft = (options.marginLeft || 0) + (printerData.marginLeft || 0);
    options.marginTop = (options.marginTop || 0) + (printerData.marginTop || 0);

    options.background = '0xffffffff';
    const url = await axiosClient.post(
      apiURL.getMergedImage,
      {
        items,
        format: '.png',
        options,
      },
      {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      }
    );

    sendImage(url.data, printer);
  },
};

export default Barcode;
