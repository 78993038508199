import userRole from 'resources/constants/userRole';

export const parsePageSettings = (pageSettings) => {
  let settings;
  if (pageSettings) {
    try {
      settings = JSON.parse(pageSettings);
    } catch {
      settings = {};
    }
  } else {
    settings = {};
  }
  return settings;
};

export const getParam = (props, paramName, defaultVal) => {
  return new URLSearchParams(props.location.search).get(paramName) || defaultVal;
};

export const getUrlPrefix = (user) => {
  if (user.role === userRole.ADMIN) return '/admin';
  else if (user.role === userRole.COMPANY_OWNER) return '/companyOwner';
  else if (user.role === userRole.COMPANY_USER) return '/companyUser';
  else if (user.role === userRole.COMPANY_ACCOUNTANT) return '/companyAccountant';
  else if (user.role === userRole.WORKER) return '/worker';
};
