import { apiURL, axiosClient } from 'service';
import {
  FETCH_CUSTOMERS_ERROR,
  FETCH_CUSTOMERS_SUCCESS,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_ERROR,
  SET_LOADING,
} from 'context/customer/keys';

export const fetchCustomers = (dispatch) => async (deviceFilter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.customers, deviceFilter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_CUSTOMERS_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_CUSTOMERS_ERROR, payload: response.error });
  }
};

export const getCustomer = (dispatch) => async (customerId) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.get(`${apiURL.customer}/${customerId}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_CUSTOMER_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_CUSTOMER_ERROR, payload: response.error });
  }
};

export const createCustomer = (dispatch) => async (customer, callback) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.customer, customer, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_CUSTOMER_SUCCESS, payload: response });
    if (callback) callback(response);
  } else {
    dispatch({ type: FETCH_CUSTOMER_ERROR, payload: response.error });
  }
};

export const updateCustomer = (dispatch) => async (customerId, customer, callback) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.put(`${apiURL.customer}/${customerId}`, customer, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_CUSTOMER_SUCCESS, payload: response });
    if (callback) callback(response);
  } else {
    dispatch({ type: FETCH_CUSTOMER_ERROR, payload: response.error });
  }
};

export const smsCustomer = (dispatch) => async (title, message, phone) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(
      apiURL.smsCustomer,
      { title, message, phone },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    )
  ).data;
};
