import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Button, Checkbox, Select, Table } from 'antd';

// contexts
import { LanguageContext, CompanyContext } from 'context';

// helpers
import { translations } from 'resources';

// styles
import styles from './index.module.scss';
import Input from 'ui/reusables/input';
import { getParam } from 'helpers/page';
import Search from 'ui/components/search/search';
import history from 'history.js';

const { Option } = Select;
export default function EditCompany(props) {
  const { id } = props.match.params;
  const {
    state: { appLanguage },
  } = useContext(LanguageContext);
  const {
    state: { company, loading },
    createCompany,
    getCompany,
    updateCompany,
  } = useContext(CompanyContext);

  const [name, setName] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [canProduct, setCanProduct] = useState(false);
  const [authorizedPerson, setAuthorizedPerson] = useState('');
  const [district, setDistrict] = useState('');

  useEffect(() => {
    if (id) getCompany(id);
  }, [id]); //eslint-disable-line

  useEffect(() => {
    if (id && company?._id === id) {
      setName(company.name);
      setFullName(company.fullName);
      setEmail(company.email);
      setPhone(company.phone);
      setAddress(company.address);
      setCity(company.city);
      setCity(company.authorizedPerson || '');
      setCity(company.district || '');
      setCanProduct(company.canProduct || false);
    }
  }, [company]); //eslint-disable-line

  return (
    <div className={styles.page_container}>
      <div className={styles.title_container}>
        <h3>{id ? translations[appLanguage]['g345'] : translations[appLanguage]['g152']}</h3>
      </div>
      <div className={styles.divider}>&nbsp;</div>
      <div style={{ width: '300px' }}>
        <Input
          label={translations[appLanguage]['g204']}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <Input
          label={translations[appLanguage]['g153']}
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
        />
        <Input
          label={translations[appLanguage]['g558']}
          value={authorizedPerson}
          onChange={(e) => setAuthorizedPerson(e.target.value)}
        />
        <Input label='Email' value={email} onChange={(e) => setEmail(e.target.value)} />
        <Input
          label={translations[appLanguage]['g1']}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />

        <Input
          label={translations[appLanguage]['g155']}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
        <Input
          label={translations[appLanguage]['g555']}
          value={district}
          onChange={(e) => setDistrict((e.target.value || '').toLocaleUpperCase())}
        />
        <Input
          label={translations[appLanguage]['g177']}
          value={city}
          onChange={(e) => setCity((e.target.value || '').toLocaleUpperCase())}
        />
        <Checkbox
          label={translations[appLanguage]['g507']}
          checked={canProduct}
          onChange={(e) => setCanProduct(e.target.checked)}
        />

        <Button
          onClick={() => {
            const customer = {
              name,
              fullName,
              email,
              phone,
              address,
              city,
              canProduct,
              authorizedPerson,
              district,
            };
            id
              ? updateCompany(id, customer, () => history.push('/admin/companies'))
              : createCompany(customer, () => history.push('/admin/companies'));
          }}
        >
          {id ? translations[appLanguage]['g345'] : translations[appLanguage]['g152']}
        </Button>
      </div>
      {/* <Search onChange={setFilter} />
      <div className={styles.table_container}>
        <Table
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                setVisible(true);
                setCurrentProduct(record);
              },
            };
          }}
          size={'middle'}
          loading={loading && !data?.length}
          bordered
          columns={columns}
          dataSource={data}
          scroll={{ x: 600, y: calcViewWidth > 995 ? calcViewHeight - 310 : calcViewHeight - 260 }}
          expandable
          pagination={{ onChange: onPageChange, current: pageIndex, pageSize }}
        />
      </div> */}
    </div>
  );
}
