import {
  CREATE_DEVICE_BARCODE_ERROR,
  CREATE_DEVICE_BARCODE_SUCCESS,
  FETCH_DEVICE_BARCODES_ERROR,
  FETCH_DEVICE_BARCODES_SUCCESS,
  FETCH_DEVICE_BARCODE_ERROR,
  FETCH_DEVICE_BARCODE_SUCCESS,
  RESET,
  SET_LOADING,
  UPDATE_DEVICE_BARCODE_ERROR,
  UPDATE_DEVICE_BARCODE_SUCCESS,
} from 'context/deviceBarcode/keys';

export const initialState = {
  deviceBarcodes: [],
  errorMessage: '',
  deviceBarcode: {},
  totalDeviceBarcodeNumber: 0,
  totalCount: 0,
  loading: true,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DEVICE_BARCODES_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        deviceBarcodes: [...payload.data],
        totalDeviceBarcodeNumber: payload.totalCount,
        loading: false,
      };
    case FETCH_DEVICE_BARCODES_ERROR:
      return { ...state, errorMessage: payload, loading: false };
    case FETCH_DEVICE_BARCODE_SUCCESS:
      return { ...state, errorMessage: '', deviceBarcode: payload, loading: false };
    case FETCH_DEVICE_BARCODE_ERROR:
      return { ...state, errorMessage: payload };
    case UPDATE_DEVICE_BARCODE_SUCCESS:
      return { ...state, errorMessage: '', deviceBarcode: payload?.data };
    case UPDATE_DEVICE_BARCODE_ERROR:
      return { ...state, errorMessage: payload };
    case CREATE_DEVICE_BARCODE_SUCCESS:
      return { ...state, errorMessage: '', deviceBarcode: payload?.data };
    case CREATE_DEVICE_BARCODE_ERROR:
      return { ...state, errorMessage: payload };
    case RESET:
      return { ...state, errorMessage: '', deviceBarcode: {} };
    default:
      return state;
  }
};

export default reducer;
