import { apiURL, axiosClient } from 'service';
import {
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_ERROR,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_ERROR,
  FETCH_COMPANY_SUCCESS,
  FETCH_COMPANY_ERROR,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,
  RESET_COMPANY,
  SET_LOADING,
  FETCH_COMPANY_NAMES_SUCCESS,
  REQUEST_ERROR,
} from 'context/company/keys';

import history from 'history.js';

export const fetchCompanies = (dispatch) => async (companyFilter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.companies, companyFilter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_COMPANIES_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_COMPANIES_ERROR, payload: response.error });
  }
};

export const getCompany = (dispatch) => async (companyID) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.get(`${apiURL.company}/${companyID}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_COMPANY_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_COMPANY_ERROR, payload: response.error });
  }
};

export const createCompany = (dispatch) => async (company) => {
  const response = (
    await axiosClient.post(apiURL.company, company, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: ADD_COMPANY_SUCCESS, payload: response });
    history.push('/admin/companies');
  } else {
    dispatch({ type: ADD_COMPANY_ERROR, payload: response.error });
  }
};

export const updateCompany = (dispatch) => async (companyId, company) => {
  const response = (
    await axiosClient.put(`${apiURL.company}/${companyId}`, company, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: UPDATE_COMPANY_SUCCESS, payload: response });
    history.push('/admin/companies');
  } else {
    dispatch({ type: UPDATE_COMPANY_ERROR, payload: response.error });
  }
};

export const resetCompany = (dispatch) => () => {
  dispatch({ type: RESET_COMPANY });
};
