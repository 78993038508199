export const FETCH_WORKERS_SUCCESS = 'FETCH_WORKERS_SUCCESS';
export const FETCH_WORKERS_ERROR = 'FETCH_WORKERS_ERROR';
export const FETCH_WORKER_SUCCESS = 'FETCH_WORKER_SUCCESS';
export const FETCH_WORKER_ERROR = 'FETCH_WORKER_ERROR';
export const ADD_WORKER_SUCCESS = 'ADD_WORKER_SUCCESS';
export const ADD_WORKER_ERROR = 'ADD_WORKER_ERROR';
export const UPDATE_WORKER_SUCCESS = 'UPDATE_WORKER_SUCCESS';
export const UPDATE_WORKER_ERROR = 'UPDATE_WORKER_ERROR';
export const RESET_WORKER = 'RESET_WORKER';
export const SET_LOADING = 'SET_LOADING';
