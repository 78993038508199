const apiUrl = {
  base: 'http://wommyapp.com/wapi/v1',
  signIn: '/wapi/v1/account/auth:signin',
  signUp: '/wapi/v1/account/auth:signup',
  devices: '/wapi/v1/device/search',
  createBarcodeExcel: '/wapi/v1/device/createBarcodeExcel',
  device: '/wapi/v1/device',
  createDevice: '/wapi/v1/device/productDevice',
  editDevice: '/wapi/v1/device/editDevice',
  editAssembledDevice: '/wapi/v1/device/editAssembledDevice',
  assemblyDevice: '/wapi/v1/device/assemblyDevice',
  controlCards: '/wapi/v1/controlCard/search',
  clearControlCard: '/wapi/v1/controlCard/clear',
  controlCard: '/wapi/v1/controlCard',
  filterBarcodes: '/wapi/v1/filterBarcode/search',
  filterBarcode: '/wapi/v1/filterBarcode',
  deviceBarcodes: '/wapi/v1/deviceBarcode/search',
  deviceBarcode: '/wapi/v1/deviceBarcode',
  customers: '/wapi/v1/customer/search',
  payments: '/wapi/v1/payment/search',
  users: '/wapi/v1/user/search',
  user: '/wapi/v1/user',
  customer: '/wapi/v1/customer',
  payment: '/wapi/v1/payment',
  smsCustomer: '/wapi/v1/customer/sms',
  companies: '/wapi/v1/company/search',
  company: '/wapi/v1/company',
  profilePhoto: '/wapi/v1/account/profile/picture',
  returnPhoto: '/wapi/v1/return/uploadReturnItemPicture',
  account: '/wapi/v1/account',
  passwordChange: '/wapi/v1/account/password',
};

export default apiUrl;
