import { apiURL, axiosClient } from 'service';
import {
  FETCH_PAYMENTS_SUCCESS,
  FETCH_PAYMENTS_ERROR,
  FETCH_PAYMENT_ERROR,
  FETCH_PAYMENT_SUCCESS,
  CREATE_PAYMENT_ERROR,
  CREATE_PAYMENT_SUCCESS,
  SET_LOADING,
} from 'context/payment/keys';

export const fetchPayments = (dispatch) => async (deviceFilter) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.payments, deviceFilter, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_PAYMENTS_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_PAYMENTS_ERROR, payload: response.error });
  }
};

export const getPayment = (dispatch) => async (_id) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.get(`${apiURL.payment}/${_id}`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_PAYMENT_SUCCESS, payload: response });
  } else {
    dispatch({ type: FETCH_PAYMENT_ERROR, payload: response.error });
  }
};

export const createPayment = (dispatch) => async (payment, callback) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.post(apiURL.payment, payment, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: CREATE_PAYMENT_SUCCESS, payload: response });
    if (callback) callback(response);
  } else {
    dispatch({ type: CREATE_PAYMENT_ERROR, payload: response.error });
  }
};

export const updatePayment = (dispatch) => async (_id, payment, callback) => {
  dispatch({ type: SET_LOADING });
  const response = (
    await axiosClient.put(`${apiURL.payment}/${_id}`, payment, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  ).data;

  if (!response.error) {
    dispatch({ type: FETCH_PAYMENT_SUCCESS, payload: response });
    if (callback) callback(response);
  } else {
    dispatch({ type: FETCH_PAYMENT_ERROR, payload: response.error });
  }
};
