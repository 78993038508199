import {
  CREATE_FILTER_BARCODE_ERROR,
  CREATE_FILTER_BARCODE_SUCCESS,
  FETCH_FILTER_BARCODES_ERROR,
  FETCH_FILTER_BARCODES_SUCCESS,
  FETCH_FILTER_BARCODE_ERROR,
  FETCH_FILTER_BARCODE_SUCCESS,
  RESET,
  SET_LOADING,
  UPDATE_FILTER_BARCODE_ERROR,
  UPDATE_FILTER_BARCODE_SUCCESS,
} from 'context/filterBarcode/keys';

export const initialState = {
  filterBarcodes: [],
  errorMessage: '',
  filterBarcode: {},
  totalFilterBarcodeNumber: 0,
  totalCount: 0,
  loading: true,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FILTER_BARCODES_SUCCESS:
      return {
        ...state,
        errorMessage: '',
        filterBarcodes: [...payload.data],
        totalFilterBarcodeNumber: payload.totalCount,
        loading: false,
      };
    case FETCH_FILTER_BARCODES_ERROR:
      return { ...state, errorMessage: payload, loading: false };
    case FETCH_FILTER_BARCODE_SUCCESS:
      return { ...state, errorMessage: '', filterBarcode: payload, loading: false };
    case FETCH_FILTER_BARCODE_ERROR:
      return { ...state, errorMessage: payload };
    case UPDATE_FILTER_BARCODE_SUCCESS:
      return { ...state, errorMessage: '', filterBarcode: payload?.data };
    case UPDATE_FILTER_BARCODE_ERROR:
      return { ...state, errorMessage: payload };
    case CREATE_FILTER_BARCODE_SUCCESS:
      return { ...state, errorMessage: '', filterBarcode: payload?.data };
    case CREATE_FILTER_BARCODE_ERROR:
      return { ...state, errorMessage: payload };
    case RESET:
      return { ...state, errorMessage: '', filterBarcode: {} };
    default:
      return state;
  }
};

export default reducer;
